import React, { useEffect, useState, useRef } from 'react';
import { firebase } from '../firebase';
import './MsgPhoto.css';

const storageRef = firebase.storage().ref();

const MsgPhoto = ({ msg, roomId, visible }) => {
  const photoRef = useRef();
  const [link, setLink] = useState('');
  const [preview, setPreview] = useState('');
  const [linkError, setLinkError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [previewError, setPreviewError] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      if (visible && !preview && msg.preview) {
        await storageRef
          .child(msg.preview)
          .getDownloadURL()
          .then(link => {
            setPreview(link);
          })
          .catch(e => {
            setPreviewError(true);
          });
      }

      if (visible && !link) {
        const imageUrl = 'sounds/' + roomId + '/' + msg.url;

        await storageRef
          .child(imageUrl)
          .getDownloadURL()
          .then(link => {
            setLink(link);
          })
          .catch(e => {
            setLinkError(true);
          });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, msg]);

  return (
    <div>
      {!!link || !!preview ? (
        <img
          ref={photoRef}
          alt=""
          className="photoImg MsgWithRadius"
          id={msg.id}
          src={preview || link}
          onLoad={() => {
            //const w = photoRef.current.clientWidth;
            //const h = photoRef.current.clientHeight;
          }}
        />
      ) : (
        <div className="photoImg photoWidth MsgWithRadius">
          {linkError ? 'Image unavailible' : 'Loading...'}
        </div>
      )}
    </div>
  );
};

export default MsgPhoto;
