import React from 'react';
import './Composer.css';
import { useState } from 'react';
import msgService from './../services/messages';
// import recordService from './../services/recorder';
import DownloadModalContext from './../contexts/DownloadModalContext';
import { useContext } from 'react';
import mic from './../img/mic.svg';
// import stop = from './../img/stop.svg';
import camera from './../img/composer_camera.svg';
import calendar from './../img/composer_calendar.svg';
import send_button from './../img/send_button.svg';
import { v4 as uuidv4 } from 'uuid';

const Composer = ({ room, user, onRec }) => {
  // const [recording, setRecording] = useState(false);
  const [text, setText] = useState('');
  const appDownloadModal = useContext(DownloadModalContext);
  const onChange = (event) => {
    setText(event.target.value || '');
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendText();
    }
  };

  const sendText = () => {
    if (text !== '') {
      const msgId = uuidv4();
      msgService.addTextMessage(msgId, room.id, user.phoneNumber, text);
      setText('');
    }
  };

  /*
  const processMicRes = result => {
    if (result.state === 'granted' || result.state === 'denied') {
      return result.state;
    } else if (result.state === 'prompt') {
      return new Promise((resolve, reject) => {
        result.onchange = function() {
          resolve(result.state);
        };
      });
    }
  };
  */

  /*
  const startRecord = () => {
    
    //navigator.permissions
    //  .query({ name: 'microphone' })
    //  .then(result => processMicRes(result))
    //  .then(state => {
    //    alert(state);
    //  });
    
    recordService.startRecord(setRecording);
  };

  const stopRecord = () => {
    recordService.stopRecord();
  };
  */

  return (
    <div className="composer">
      <div className="composerBar">
        <div className="composerButton" onClick={appDownloadModal.toggle}>
          <img alt="calendar" src={calendar} width="18" height="20" />
        </div>
        <div className="composerButton" onClick={appDownloadModal.toggle}>
          <img alt="camera" src={camera} width="20" height="18" />
        </div>
      </div>
      <div className="inputArea">
        <input
          value={text}
          onChange={onChange}
          onKeyPress={onKeyPress}
          id="textInput"
          className="textInput"
          placeholder="Message..."
          type="text"
          autoComplete="off"
          autoCorrect="false"
        />
        {text ? (
          <img src={send_button} alt="" onClick={sendText} />
        ) : (
          <div style={{ width: '20px' }} />
        )}
      </div>
      <div className="emptyWidth" />
      <div onClick={onRec} className="micContainer">
        <div className="micButton">
          <img alt="" src={/*recording ? stop : mic*/ mic} />
        </div>
      </div>
    </div>
  );
};

export default Composer;
