import React, { Fragment, useState, useEffect, useRef } from 'react';
// import DarkModeContext from "../../contexts/DarkModeContext";

const isInViewport = (elem) => {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
const areEqual = (prevProps, nextProps) =>
  prevProps.eventEmitter === nextProps.eventEmitter;

const Word = React.memo(({ e, eventEmitter }) => {
  const wordRef = useRef();
  const [active, setActive] = useState(false);
  const { isDarkMode } = false;

  const onEnter = () => {
    if (wordRef && wordRef.current) {
      if (!isInViewport(wordRef.current)) {
        // wordRef.current.scrollIntoView();
      }
    }
    setActive(true);
  };
  const onExit = () => {
    setActive(false);
  };

  useEffect(() => {
    e.addEventListener('enter', onEnter);
    e.addEventListener('exit', onExit);
    return () => {
      e.removeEventListener('enter', onEnter);
      e.removeEventListener('exit', onExit);
    };
  }, [e, eventEmitter]);

  const _text = e.text || '';
  const texts = _text.trim().split(' ');

  return texts.map((text, npp) => {
    const lastSymbol = text.slice(-1);
    const isAlphaNumeric = lastSymbol.match(/^[0-9a-z]+$/);

    const activBackgroundColor = isDarkMode ? '#7E7BFA' : '#0500FF1E';
    const BackgroundColor = isDarkMode ? '#1F1F1F' : '#FFFFFF';
    const color = isDarkMode ? '#FFFFFF' : '#2F374D';

    const style = {
      backgroundColor: active ? activBackgroundColor : BackgroundColor,
      color: color,
    };

    return (
      <span
        ref={wordRef}
        className={isAlphaNumeric ? 'wordPad' : 'punctuationPad'}
        onClick={() => {
          eventEmitter.emit('playStart', e.startTime);
          eventEmitter.emit('tapScrub');
        }}
        style={style}
        key={`${e.id}-${npp}`}>
        <Fragment>{text}</Fragment>
      </span>
    );
  });
}, areEqual);

export default Word;
