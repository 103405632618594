import parsePhoneNumber from 'libphonenumber-js';

export const formatPhoneNumber = (number, defaultCountries = ['US']) => {
  let phoneNumber;
  try {
    phoneNumber = parsePhoneNumber(number);
  } catch (e) {
    console.error('formatPhoneNumber ERROR', e);
    phoneNumber = null;
  }
  if (
    phoneNumber &&
    phoneNumber.isValid() &&
    defaultCountries.includes(phoneNumber.country)
  ) {
    return phoneNumber.formatNational();
  } else {
    return number;
  }
};
