import Box, { BoxHoc } from 'components/Box';
import arrowBack from 'img/arrow_back_gray.svg';

import feedback from 'img/feedback_b.svg';
import medcorder from 'img/medcorder_outline_b.svg';
import help from 'img/help_b.svg';

const ABox = BoxHoc('a');

const MenuBottomBtn = ({ pic, text, onClick, linkSettings }) => {
  return (
    <ABox
      display="flex"
      alignItems="center"
      alignContent="flex-start"
      height="58px"
      paddingX="18px"
      marginTop="20px"
      css={{ cursor: 'pointer', textDecoration: 'none' }}
      backgroundColor="#FEFEFE"
      borderRadius="8px"
      {...linkSettings}
      onClick={onClick}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <img src={pic} alt="" />
        <Box
          fontWeight="600"
          paddingLeft="14px"
          fontSize="17px"
          color="#2F374D">
          {text}
        </Box>
      </Box>
    </ABox>
  );
};

const ProfileMenu = ({ left, back }) => {
  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      backgroundColor={'#FFFFFF'}
      position="fixed"
      top="0"
      bottom="0"
      left={left || '0'}
      right="0">
      <Box display="flex" flex={1} flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="65px"
          paddingX="21px"
          alignItems="center"
          backgroundColor={'white'}>
          <Box
            onClick={back}
            css={{ cursor: 'pointer' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="65px"
            width="65px">
            <img src={arrowBack} alt="" />
          </Box>
          <Box fontWeight="600" fontSize="17px">
            Help & About
          </Box>
          <Box height="65px" width="65px" />
        </Box>
        <Box
          display="flex"
          paddingX="21px"
          flex={1}
          flexDirection="column"
          overflowY="auto"
          overflowX="hidden"
          scrollbarWidth="none"
          backgroundColor={'#F9F9FA'}>
          <MenuBottomBtn
            pic={help}
            text="FAQs"
            linkSettings={{
              href: 'https://www.medcorder.com/faqs',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
          <MenuBottomBtn
            pic={medcorder}
            text="About the Company"
            linkSettings={{
              href: 'https://www.medcorder.com/about',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
          <MenuBottomBtn
            pic={feedback}
            text="Send Feedback"
            linkSettings={{
              href: 'mailto:team@medcorder.com',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileMenu;
