import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import close from './../img/modal_close2.svg';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 13px;
  border-radius: 12px;
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ImgDivider = styled.div`
  width: 20px;
`;

const CancelButton = styled.div`
  width: 180px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid #7674e2;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #7674e2;
`;

const YesButton = styled.div`
  width: 180px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #7674e2;
  border-radius: 8px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #ffffff;
`;

const ModalText = styled.div`
  font-family: Proxima Nova;
  font-weight: bold;
  font-size: 20px;
  line-height: 107.2%;
  /* or 21px */

  text-align: center;

  color: #2f374d;

  margin-top: 30px;
  margin-bottom: 25px;
  max-width: 500px;
`;

export const useMsgSettingsModal = () => {
  const [isOpen, toggle] = useState(false);
  const [tapping, setTapping] = useState(false);
  const [msg, setMsg] = useState();
  const [onSelect, setOnSelect] = useState();
  useEffect(() => {
    let timeout;
    if (tapping) {
      timeout = setTimeout(() => {
        console.log('toggle');
        toggle(true);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tapping]);
  return {
    isOpen,
    toggle: (active) => {
      toggle(active);
      setTapping(false);
    },
    setTapping,
    msg,
    setMsg,
    onSelect,
    setOnSelect,
  };
};

const MsgSettingsModal = ({ isOpen, toggle, msg, onSelect }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Column>
        <CloseContainer>
          <img alt="" src={close} onClick={() => toggle()} />
        </CloseContainer>
        <ModalText>Would you like to delete this message?</ModalText>
        <CenterRow>
          <CancelButton
            onClick={() => {
              onSelect('NO', msg.id);
              toggle(false);
            }}>
            Cancel
          </CancelButton>
          <ImgDivider />
          <YesButton
            onClick={() => {
              onSelect('YES', msg.id);
              toggle(false);
            }}>
            Yes, remove
          </YesButton>
        </CenterRow>
      </Column>
    </Modal>
  );
};

export default MsgSettingsModal;
