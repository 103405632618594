import React from 'react';
import './RoomUsersItem.css';
import { useContext } from 'react';
import UserContext from './../contexts/UserContext';
import { formatPhoneNumber } from './../utils/phoneUtils';

const parseType = (t) => {
  if (t === 'guest') return 'Member';
  return (t || '').toUpperCase();
};

const RoomUsersItem = ({ num, name, type }) => {
  const authUser = useContext(UserContext);
  const showName = authUser.phoneNumber === num ? 'Me' : name ? name : num;
  return (
    <div className="roomsUserItem">
      <div className="roomsUserName">{formatPhoneNumber(showName)}</div>
      <div className={'roomsUserType' + (type === 'patient' ? '_patient' : '')}>
        {parseType(type)}
      </div>
    </div>
  );
};

export default RoomUsersItem;
