import React, { useContext } from 'react';
import './RoomsList.css';
import RoomListItem, { getRoomLastDate } from './RoomListItem';
import DownloadModalContext from './../contexts/DownloadModalContext';
import plus from './../img/plus.svg';
const RoomsList = (props) => {
  const { rooms, onChange, curRoomID } = props;
  const appDownloadModal = useContext(DownloadModalContext);

  return (
    <div className="roomsListContainer">
      <div>
        {Object.keys(rooms)
          .filter((r) => rooms[r] && rooms[r]['id'])
          .sort((a, b) => {
            const roomA = rooms[a];
            const roomB = rooms[b];
            const diff = getRoomLastDate(roomB)[0] - getRoomLastDate(roomA)[0];
            return diff;
          })
          .map((key, index) => {
            const room = rooms[key];
            return (
              <RoomListItem
                key={key}
                index={index}
                room={room}
                onClick={onChange}
                isCurRoom={curRoomID === key}
              />
            );
          })}
        <div
          key="ad"
          className={'roomListItem'}
          onClick={appDownloadModal.toggle}>
          <div className="itemCircle" style={{ backgroundColor: '#222148' }}>
            <img src={plus} alt="" />
          </div>
          <div className="itemDescription">
            <div className="itemName">{'New Patient'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomsList;
