import { useState, useEffect } from 'react';

import {
  MAX_MOBILE_SIZE,
  DEFAULT_STATIC_MENU_WIDTH,
  MAX_POPUP_MENU_PERCENT,
  MAX_POPUP_MENU_WIDTH,
} from './../constants/sizes';

const getWidth = () => {
  return window.innerWidth;
};

const getHeight = () => {
  return window.innerHeight;
};

const getSize = () => {
  const width = getWidth();
  const height = getHeight();
  const isMobileSize = width <= MAX_MOBILE_SIZE;
  const isDesktopSize = !isMobileSize;

  const staticMenuWidth = DEFAULT_STATIC_MENU_WIDTH;
  const popupMenuWidth = Math.min(
    (width - (isDesktopSize ? staticMenuWidth : 0)) * MAX_POPUP_MENU_PERCENT,
    MAX_POPUP_MENU_WIDTH,
  );
  const mainViewWidth = isDesktopSize ? width - staticMenuWidth : width;

  return {
    width,
    height,
    isMobileSize,
    isDesktopSize,
    staticMenuWidth,
    popupMenuWidth,
    mainViewWidth,
  };
};

const useWindowsSize = () => {
  const [size, setSize] = useState(getSize());
  const updateSize = () => {
    setSize(getSize());
  };
  useEffect(() => {
    window.addEventListener('resize', updateSize, false);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export default useWindowsSize;
