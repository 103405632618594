import { useState, useEffect } from 'react';
import Recorder from '../recorder/recorder';
import { firebase } from '../firebase';

import { PROTOCOL_VERSION } from '../constants/versions';

import { v4 as uuidv4 } from 'uuid';

const getFirebase = () => {
  return firebase;
};

let microphone;
let audioContext;
let recorder;
let sampleRate;
let recordID;
let socket;
let recordings = {};
let emptyAudioTimeout;

const captureMicrophone = async (setMicError) => {
  if (microphone) {
    return microphone;
  }

  if (
    typeof navigator.mediaDevices === 'undefined' ||
    !navigator.mediaDevices.getUserMedia
  ) {
    if (!!navigator.getUserMedia) {
      console.warn(
        'This browser seems supporting deprecated getUserMedia API.',
      );
    }
    setMicError("This browser doesn't support audio recording");
  }

  try {
    const mic = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    microphone = mic;
    return microphone;
  } catch (error) {
    //alert('Unable to capture your microphone. Please check console logs.');
    setMicError(
      'Microphone not available; please check your permissions and try again.',
    );
    console.error(error);
  }
};

const __log = console.log;

const startUserMedia = (stream) => {
  audioContext = new AudioContext({
    sampleRate: 16000,
  });
  const input = audioContext.createMediaStreamSource(stream);
  sampleRate = input.context.sampleRate;
  __log('Media stream created.', sampleRate);

  // Uncomment if you want the audio to feedback directly
  //input.connect(audio_context.destination);
  //__log('Input connected to audio context destination.');

  recorder = new Recorder(input, { numChannels: 1 });
  recorder.onChunkBuffer = onChunkBuffer;
  __log('Recorder initialised.');
};

const startEmptyAudioTimeout = (onEmptyAudioTimeout) => {
  stopEmptyAudioTimeout();
  emptyAudioTimeout = setTimeout(() => {
    onEmptyAudioTimeout && onEmptyAudioTimeout();
  }, 2000);
};

const stopEmptyAudioTimeout = () => {
  if (emptyAudioTimeout) {
    clearTimeout(emptyAudioTimeout);
    emptyAudioTimeout = null;
  }
};

const onChunkBuffer = (data) => {
  const { chunkIndex, chunkLength } = data;
  if (recordID) {
    stopEmptyAudioTimeout();
    const rID = recordID;
    const ts = Date.now();
    console.log('processing', rID, ts, chunkIndex, chunkLength);
    socket && socket.emit('chunkData', data);
  }
};

const useRecording = (params = {}) => {
  const { onError, onEmptyAudio, logEvent, type, questionID, answerID } =
    params;
  const setMicError =
    onError ||
    ((text) => alert(text || 'Recording is not supported on this browser.'));
  const [recording, setRecording] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [recordingStart, setRecordingStart] = useState(0);
  const [transcription, setTranscription] = useState({});
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    return () => {
      // stopSocket();
    };
  }, []);

  /*
  const startSocket = async () => {
    return new Promise((resolve) => {
      console.log('connecting to socket');
      socket = io(ioPoint, {
        // WARNING: in that case, there is no fallback to long-polling
        transports: ['websocket'], // or [ 'websocket', 'polling' ], which is the same thing
      });

      socket.on('connect', (data) => {
        console.log('SOCKET CONNECTED');
        socket.emit('join', 'Server Connected to Client');
        resolve('CONNECTED');
      });

      socket.on('streamReady', (data) => {
        recordID = data.recordID;
        recorder && recorder.clear();
        recorder && recorder.record();
        setRecordingStart(Date.now());
        setRecording(true);
        startEmptyAudioTimeout(onEmptyAudioTimeout);
        logEvent && logEvent('recording_begun');
        __log('Recording...');
      });

      socket.on('disconnect', (reason) => {
        console.log('SOCKET DISCONNECTED', `reason: ${reason}`);
        if (recordID) {
          stopRecord();
          setMicError(
            'Lost connection to server. Please check your Internet connection and try again.',
          );
        }
      });

      socket.on('needDisconnect', (data = {}) => {
        stopRecord();
        // RECOGNITION_ERROR, BLURB_AUTH_FAILED
        let errorText =
          'Lost connection to server. Please check your Internet connection and try again.';
        if (data.reason === 'RECOGNITION_ERROR') {
          errorText = 'There was an issue audio recognition!';
        } else if (data.reason === 'BLURB_AUTH_FAILED') {
          errorText = 'There was an authentication issue!';
        } else if (data.reason === 'WRONG_CLIENT_VERSION') {
          errorText = "Can't start recording, refresh page please!";
        }
        setMicError(errorText);
      });

      socket.on('messages', function (data) {
        console.log(data);
      });

      socket.on('transcription', function (tr) {
        const { recordID, resultID } = tr;
        recordings[recordID] = recordings[recordID] || {};
        const transcriptions = recordings[recordID];
        transcriptions[resultID] = tr;
        setTranscription({ ...transcriptions });
      });
    });
  };

  const stopSocket = () => {
    if (socket) {
      socket.close();
      socket = null;
    }
  };
  */

  const startRecord = async () => {
    recordID = `${uuidv4()}`;
    if (recording || processing) {
      return;
    }
    const mic = await captureMicrophone(setMicError);
    if (!mic) return;
    microphone = mic;
    setProcessing(true);
    recordings = {};
    setTranscription({});
    const idToken = await getFirebase().auth().currentUser.getIdToken();
    // sampleRate assigned in  startUserMedia function
    startUserMedia(mic);
    // await startSocket();
    //>>
    recorder && recorder.clear();
    recorder && recorder.record();
    setRecordingStart(Date.now());
    setRecording(true);
    //<<
    socket &&
      socket.emit('startSpeachStream', {
        protocolVersion: PROTOCOL_VERSION,
        sampleRate,
        idToken,
        type,
        questionID,
        answerID,
      });
  };

  const onEmptyAudioTimeout = () => {
    if (recordID) {
      const lastID = recordID;
      stopRecord();
      onEmptyAudio && onEmptyAudio(lastID);
    }
  };

  const stopRecord = (fn) => {
    const endTs = Date.now();
    const lastID = recordID;
    recordID = '';
    recorder && recorder.stop();
    socket && socket.emit('endSpeachStream', '');
    setFinishing(true);
    setRecording(false);
    setFinishing(false);
    setRecordingStart(0);
    setProcessing(false);

    recorder.exportWAV((b) => {
      if (fn) {
        fn({
          startTs: recordingStart,
          endTs: endTs,
          id: lastID,
          blob: b,
        });
      }
      // Recorder.forceDownload(b, 'record.wav');
    });

    logEvent &&
      logEvent('recording_ended', { length: Date.now() - recordingStart });
    /*
    new Promise((resolve) => {
      let finTimeout = setTimeout(() => {
        console.log('SOCKET TIMEOUT EVENT');
        finTimeout = null;
        // stopSocket();
        setRecording(false);
        setFinishing(false);
        setRecordingStart(0);
        setProcessing(false);
        if (fn) {
          fn(lastID);
        }
      }, 10000);
      socket &&
        socket.on('finished', () => {
          if (finTimeout) {
            console.log('SOCKET FINISH EVENT');
            clearTimeout(finTimeout);
            finTimeout = null;
            // stopSocket();
            setRecording(false);
            setFinishing(false);
            setRecordingStart(0);
            setProcessing(false);
            if (fn) {
              fn(lastID);
            }
          }
        });
    });
    */

    if (microphone) {
      console.log('free mic tracks');
      microphone.getTracks().forEach(function (track) {
        track.stop();
      });
      microphone = null;
    }
  };

  return {
    recording,
    processing,
    finishing,
    recordingStart,
    transcription,
    startRecord,
    stopRecord,
  };
};

export default useRecording;
