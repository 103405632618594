import React from 'react';

import RoomUsersItem from './RoomUsersItem';

const RoomUsers = ({ room }) => {
  const authorizedUsers = room.authorizedUsers || {};
  const membersInfo = room.membersInfo || {};
  return Object.keys(authorizedUsers).map((num) => {
    const name = membersInfo[num]?.name;
    return (
      <RoomUsersItem
        key={num}
        num={num}
        name={name}
        type={authorizedUsers[num]}
      />
    );
  });
};

export default RoomUsers;
