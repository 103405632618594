import React, { useContext } from 'react';
import './Message.css';
import MsgText from './MsgText';
import MsgAudio from './MsgAudio';
import MsgPhoto from './MsgPhoto';
import MsgVideo from './MsgVideo';
import MsgGps from './MsgGps';
import UserContext from './../contexts/UserContext';
import { formatAMPM } from './../utils/dateUtils';
import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import WinSizeContext from './../contexts/WinSizeContext';

const VisCheck = ({ children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <VisibilitySensor
      onChange={(vis) => {
        if (vis) {
          setVisible(true);
        }
      }}>
      {children({ isVisible: visible })}
    </VisibilitySensor>
  );
};

const Message = ({ msg, appointment, roomId, isMyMsg, visible }) => {
  switch (msg.type) {
    case 'Text':
      return <MsgText msg={msg} isMyMsg={isMyMsg} visible={visible} />;
    case 'Audio':
      return (
        <MsgAudio
          msg={msg}
          appointment={appointment}
          roomId={roomId}
          visible={visible}
        />
      );
    case 'Photo':
      return <MsgPhoto msg={msg} roomId={roomId} visible={visible} />;
    case 'Video':
      return <MsgVideo msg={msg} roomId={roomId} visible={visible} />;
    case 'Gps':
      return <MsgGps msg={msg} roomId={roomId} visible={visible} />;
    default:
      return <div> message of type: {msg.type} </div>;
  }
};

const MessageContainer = ({
  msg,
  appointment,
  memberInfo,
  roomId,
  hideHeader,
}) => {
  const authUser = useContext(UserContext);
  const winSize = useContext(WinSizeContext);
  const isMyMsg = authUser.phoneNumber === msg.user;
  const cls = isMyMsg ? 'alignRight' : 'alignLeft';
  const inv = isMyMsg ? 'inv' : '';
  const date = new Date(msg.timestamp);
  const dt = formatAMPM(date);
  const isMobile = winSize.isMobileSize;
  const leftMargin = isMobile ? 24 : 30;
  const rightMargin = 50;
  const mobileMargin = isMobile ? 24 : 30;
  const outerStyle = {
    marginLeft: mobileMargin,
    marginRight: mobileMargin,
  };
  const MAX_MSG_WIDTH = 780;

  const isAudio = msg.type === 'Audio';

  const bubbleStyle = /*isAudio ? { minWidth: '100%', maxWidth: null }: */ {
    width: isAudio ? '100%' : null,
    maxWidth: Math.min(
      MAX_MSG_WIDTH,
      winSize.mainViewWidth -
        leftMargin -
        (isMobile ? mobileMargin : rightMargin),
    ),
  };
  const memberName = memberInfo?.name;

  const userName =
    msg.user === 'sys'
      ? 'Medcorder'
      : isMyMsg
      ? 'Me'
      : memberName
      ? memberName
      : msg.user;

  return (
    <div id={'msg-' + msg.id}>
      <div className={'msgOuter ' + cls} style={outerStyle}>
        <div className="msgBubble" style={bubbleStyle}>
          {!hideHeader ? (
            <div className={'messageHeader ' + inv}>
              <div className="userName">{userName}</div>
              <div style={{ width: 7 }}></div>
              <div className="msgDate">{dt}</div>
            </div>
          ) : null}
          <div>
            <VisCheck>
              {({ isVisible }) => (
                <Message
                  msg={msg}
                  appointment={appointment}
                  roomId={roomId}
                  isMyMsg={isMyMsg}
                  visible={isVisible}
                />
              )}
            </VisCheck>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageContainer;
