import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
}

const modalBackgroundPoses = {
  open: {
    background: 'rgba(47, 55, 77, 0.7)',
    applyAtStart: {
      display: 'flex',
    },
  },
  closed: {
    applyAtEnd: {
      display: 'none',
    },
  },
};

const ModalBackground = styled(posed.div(modalBackgroundPoses))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const modalPoses = {
  open: {
    opacity: 1,
    transition: {
      opacity: {
        type: 'tween',
        duration: 200,
      },
    },
  },
  closed: {
    opacity: 0,
    transition: {
      opacity: {
        type: 'tween',
        duration: 200,
      },
    },
  },
};

const Modal = styled(posed.div(modalPoses))`
  position: relative;
  z-index: 1000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  max-width: 540px;
  margin: 30px;
`;

const FModal = styled(posed.div(modalPoses))`
  position: relative;
  z-index: 1000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  margin: 30px;
`;

export default function ({ isOpen, toggle, children }) {
  const ref = useRef();

  useOnClickOutside(ref, () => toggle(false));

  return (
    <ModalBackground initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
      <Modal ref={ref}>{children}</Modal>
    </ModalBackground>
  );
}

export const FullModal = function ({ isOpen, toggle, children }) {
  const ref = useRef();

  useOnClickOutside(ref, () => toggle(false));

  return (
    <ModalBackground initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
      <FModal ref={ref}>{children}</FModal>
    </ModalBackground>
  );
};
