function worker() {
  let chunkIndex = 0;
  let chunks = {};
  /*
    let recLength = 0,
      recBuffers = [];
    */
  let sampleRate, numChannels;
  const recBufCount = 3;

  this.onmessage = function (e) {
    switch (e.data.command) {
      case 'init':
        init(e.data.config);
        break;
      case 'record':
        record(e.data.buffer);
        break;
      case 'exportWAV':
        exportWAV(e.data.type);
        break;
      case 'getBuffer':
        getBuffer();
        break;
      case 'clear':
        clear();
        break;
      case 'clearChunkIndex':
        clearChunkIndex(e.data.index);
        break;
      default:
        break;
    }
  };

  function getCurrentChunk() {
    chunks[chunkIndex] = chunks[chunkIndex] || {
      index: chunkIndex,
      recBufCount: 0,
      recLength: 0,
      recBuffers: createBuffers(),
    };
    return chunks[chunkIndex];
  }

  function clearChunkIndex(index) {
    delete chunks[index];
  }

  function init(config) {
    sampleRate = config.sampleRate;
    numChannels = config.numChannels;
    chunks = {};
    console.log('sampleRate', sampleRate);
  }

  function record(inputBuffer) {
    const chunk = getCurrentChunk();
    for (var channel = 0; channel < numChannels; channel++) {
      chunk.recBuffers[channel].push(inputBuffer[channel]);
    }
    chunk.recLength += inputBuffer[0].length;
    chunk.recBufCount++;
    if (chunk.recBufCount >= recBufCount) {
      incChunk();
    }
  }

  function exportWAV(type) {
    const recBuffers = [];
    let recLength = 0;
    Object.keys(chunks)
      .map((chunkId) => chunks[chunkId])
      .sort((a, b) => a.index > b.index)
      .forEach((chunk) => {
        recLength += chunk.recLength;
        for (let channel = 0; channel < numChannels; channel++) {
          recBuffers[channel] = (recBuffers[channel] || []).concat(
            chunk.recBuffers[channel],
          );
        }
      });
    let buffers = [];
    for (let channel = 0; channel < numChannels; channel++) {
      buffers.push(mergeBuffers(recBuffers[channel], recLength));
    }
    let interleaved;
    if (numChannels === 2) {
      interleaved = interleave(buffers[0], buffers[1]);
    } else {
      interleaved = buffers[0];
    }
    let dataview = encodeWAV(interleaved);
    let audioBlob = new Blob([dataview], { type: type });

    this.postMessage({ command: 'exportWAV', data: audioBlob });
  }

  function getBuffer() {
    const recBuffers = [];
    let recLength = 0;
    Object.keys(chunks)
      .sort()
      .forEach((chunkId) => {
        const chunk = chunks[chunkId];
        recLength += chunk.recLength;
        for (let channel = 0; channel < numChannels; channel++) {
          recBuffers[channel] = (recBuffers[channel] || []).concat(
            chunk.recBuffers[channel],
          );
        }
      });

    let buffers = [];
    for (let channel = 0; channel < numChannels; channel++) {
      buffers.push(mergeBuffers(recBuffers[channel], recLength));
    }
    this.postMessage({ command: 'getBuffer', data: buffers });
  }

  function clear() {
    chunkIndex = 0;
    chunks = {};
  }

  function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l);
    while (l--) {
      buf[l] = Math.min(1, buffer[l]) * 0x7fff;
    }
    return buf.buffer;
  }

  function sendChunkBuffer(chunk) {
    let buffers = [];

    if (chunk && chunk.recLength > 0) {
      buffers = convertFloat32ToInt16(
        mergeBuffers(chunk.recBuffers[0], chunk.recLength),
      );
      // let audioBlob = new Blob([buffers], { type: 'application/octet-binary' });
      this.postMessage({
        command: 'chunkBuffer',
        data: {
          // audioBlob,
          chunkBuffer: buffers,
          chunkIndex: chunk.index,
          chunkLength: chunk.recLength,
        },
      });
    }
  }

  function incChunk() {
    const curIndex = chunkIndex++;
    const chunk = chunks[curIndex];
    sendChunkBuffer(chunk);
  }

  function createBuffers() {
    const recBuffers = [];
    for (let channel = 0; channel < numChannels; channel++) {
      recBuffers[channel] = [];
    }
    return recBuffers;
  }

  function mergeBuffers(recBuffers, recLength) {
    let result = new Float32Array(recLength);
    let offset = 0;
    for (let i = 0; i < recBuffers.length; i++) {
      result.set(recBuffers[i], offset);
      offset += recBuffers[i].length;
    }
    return result;
  }

  function interleave(inputL, inputR) {
    let length = inputL.length + inputR.length;
    let result = new Float32Array(length);

    let index = 0,
      inputIndex = 0;

    while (index < length) {
      result[index++] = inputL[inputIndex];
      result[index++] = inputR[inputIndex];
      inputIndex++;
    }
    return result;
  }

  function floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
  }

  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  function encodeWAV(samples) {
    console.log(samples.length);
    let buffer = new ArrayBuffer(44 + samples.length * 2);
    let view = new DataView(buffer);

    /* RIFF identifier */
    writeString(view, 0, 'RIFF');
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * 2, true);
    /* RIFF type */
    writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, 1, true);
    /* channel count */
    view.setUint16(22, numChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * 4, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, numChannels * 2, true);
    /* bits per sample */
    view.setUint16(34, 16, true);
    /* data chunk identifier */
    writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, samples.length * 2, true);

    floatTo16BitPCM(view, 44, samples);

    return view;
  }
}
export default worker;
