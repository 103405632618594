import React from 'react';
import './MsgText.css';

const MsgText = ({ msg, isMyMsg }) => {
  const clr = isMyMsg ? 'colorPurple' : 'colorWhite';

  return (
    <div className={'MsgWithRadius ' + clr}>
      <div
        className={
          'msgText MsgPaddingHor MsgPaddingVer ' + (isMyMsg ? 'rightMsg ' : ' ')
        }
      >
        {msg.text || ''}
      </div>
    </div>
  );
};

export default MsgText;
