import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './SignIn.css';
import logowithtxt from './logowithtxt.svg';

import IMask from 'imask';

import { firebase } from '../firebase';

import useWindowsSize from './../hooks/WindowSize';

const SignInPage = ({ history, phone }) => {
  return (
    <div>
      <SignInForm history={history} phone={phone} />
    </div>
  );
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  step: 0,
  phone: '',
  code: '',
  error: null,
};

const checkPhone = (phone) => {
  if (phone && phone.startsWith('+1')) {
    return phone.replace('+1', '');
  } else {
    return phone || '';
  }
};

const RenderPhoneInput = ({
  phone,
  phoneSubmitting,
  mask,
  error,
  onPhoneChange,
  onPhoneSubmit,
}) => {
  const { width } = useWindowsSize();
  const unmaskedValue = (mask && mask.unmaskedValue) || '';

  const isInvalid = unmaskedValue === '' || unmaskedValue.length < 10;

  if (phoneSubmitting) return <div id="sign-in-button" />;

  return (
    <div className="SignInContainer">
      <img
        className="logo"
        src={logowithtxt}
        alt=""
        width="169px"
        height="40px"
        style={{
          alignSelf: 'flex-start',
          marginLeft: '40px',
          display: width > 768 ? 'flex' : 'none',
        }}
      />
      <div className="pageInner">
        <img
          className="logo"
          src={logowithtxt}
          alt=""
          width="169px"
          height="40px"
          style={{
            display: width > 768 ? 'none' : 'flex',
          }}
        />
        <div className="pageHeader">Login</div>
        <div className="inputDescription"> Enter mobile phone </div>
        <form className="inputForm" onSubmit={onPhoneSubmit}>
          <div
            style={{
              display: 'flex',
            }}>
            <div
              className="numInput"
              style={{ paddingTop: '1px', paddingRight: '8px' }}>
              +1
            </div>
            <input
              data-inputmask="'mask': '(999) 999-9999'"
              id="numInp"
              className="numInput fullWidth"
              value={phone}
              onChange={(event) => onPhoneChange(event.target.value)}
              type="tel"
              autoComplete="tel"
              placeholder="(000) 000-0000"
            />
          </div>

          <button
            className="sendButton"
            key="phone-send"
            disabled={isInvalid}
            type="submit"
            id="sign-in-button">
            Next
          </button>
          {error && <p className="loginErrorText">{error.message}</p>}
        </form>
        <div className="formDescriptionHeader">Why phone number?</div>
        <div className="formDescription">
          It’s safe and easy: no need to remember a password and we don’t sell
          your number to anyone.
        </div>
      </div>
    </div>
  );
};

const RenderCodeInput = ({
  phone,
  code,
  error,
  onCodeChange,
  onCodeSubmit,
  onCodeResend,
}) => {
  const isInvalid = code === '';
  const { width } = useWindowsSize();

  return (
    <div>
      <div className="SignInContainer">
        <img
          className="logo"
          src={logowithtxt}
          alt=""
          width="169px"
          height="40px"
          style={{
            alignSelf: 'flex-start',
            marginLeft: '40px',
            display: width > 768 ? 'flex' : 'none',
          }}
        />
        <div className="pageInner">
          <img
            className="logo"
            src={logowithtxt}
            alt=""
            width="169px"
            height="40px"
            style={{
              display: width > 768 ? 'none' : 'flex',
            }}
          />
          <div className="pageHeader">Enter the code</div>
          <div className="inputDescription">
            To verify your identity, we've sent a code to
            <span style={{ fontWeight: '800' }}>{` +1 ${phone}`}</span>.
            <span
              style={{ color: '#7674E2', cursor: 'pointer' }}
              onClick={onCodeResend}>{` Resend`}</span>
          </div>
          <form className="inputForm" onSubmit={onCodeSubmit}>
            <input
              className="numInput fullWidth"
              value={code}
              onChange={(event) => {
                onCodeChange(event.target.value);
              }}
              type="number"
              pattern="[0-9]*"
              autoComplete="one-time-code"
              placeholder="000000"
            />

            <button
              className="sendButton"
              key="code-send"
              disabled={isInvalid}
              type="submit"
              id="sign-in-button">
              Login
            </button>

            {error && <p className="loginErrorText">{error.message}</p>}
          </form>
          <div
            className="formDescription"
            style={{ fontSize: '16px', lineHeight: '22px' }}>
            By pressing this button you agree with
            <a
              className="aLink"
              href="https://www.medcorder.com/terms"
              target="_blank"
              rel="noopener noreferrer">
              Terms Of Use
            </a>
            and
            <a
              className="aLink"
              href="https://www.medcorder.com/privacy"
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    const { phone } = props;
    const checkedPhone = checkPhone(phone) || '';

    this.state = { ...INITIAL_STATE, phone: checkedPhone };
    this.confirmationResult = null;
  }

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
          // alert('reCAPTCHA solved');
        },
      },
    );
    this.element = document.getElementById('numInp');
    if (this.element) {
      var maskOptions = {
        mask: '(000) 000-0000',
      };
      this.mask = IMask(this.element, maskOptions);
      this.mask.updateValue();
      this.mask.on('complete', () => {
        console.log('complete', this.mask.value);
        this.setState(byPropKey('phone', this.mask.value));
      });

      if (this.mask.unmaskedValue) {
        this.onPhoneSubmit();
        this.setState({ phone: this.mask.value, phoneSubmitting: true });
      }
    }
  }

  onCodeResend = () => {
    this.setState({
      step: 0,
      phoneSubmitting: true,
    });
    setTimeout(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
            // alert('reCAPTCHA solved');
          },
        },
      );
      this.onPhoneSubmit();
    }, 1000);
  };

  onPhoneSubmit = (event) => {
    event && event.preventDefault();
    const mask = this.mask || {};
    const unmaskedValue = mask.unmaskedValue || '';

    const phoneToSend = '+1' + unmaskedValue;

    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneToSend, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.confirmationResult = confirmationResult;
        this.setState({ step: 1, phoneSubmitting: false });
        //this.setState(() => ({ ...INITIAL_STATE }));
        //history.push(routes.HOME);
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        this.setState({ error: error, phoneSubmitting: false });
      });
  };

  onCodeSubmit = (event) => {
    event && event.preventDefault();
    const { code } = this.state;

    if (this.confirmationResult) {
      this.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          var user = result.user;
          console.log(user);
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          this.setState(byPropKey('error', error));
        });
    }
  };

  render() {
    const { step, phone, code, error, phoneSubmitting } = this.state;
    return step === 0 ? (
      <RenderPhoneInput
        phone={phone}
        phoneSubmitting={phoneSubmitting}
        mask={this.mask}
        error={error}
        onPhoneChange={(newPhone) => {
          this.setState(byPropKey('phone', newPhone));
        }}
        onPhoneSubmit={this.onPhoneSubmit}
      />
    ) : (
      <RenderCodeInput
        phone={phone}
        code={code}
        onCodeChange={(newCode) => {
          this.setState(byPropKey('code', newCode));
        }}
        onCodeSubmit={this.onCodeSubmit}
        onCodeResend={this.onCodeResend}
      />
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
