import React, { useRef, useState, useEffect } from 'react';
import './Player.css';
import PlayerRange from './PlayerRange';
import eventEmitter from './../services/eventEmitter';
import playerPlay from './../img/play_large.svg';
import playerPause from './../img/pause_large.svg';
import center from './../img/center.svg';
import Box from 'components/Box';

const playRates = [1, 1.5, 2];
const PLAY_RATE_KEY = 'PLAY_RATE_KEY';

const getSavedRate = () => {
  try {
    const playRateStr = localStorage.getItem(PLAY_RATE_KEY);
    const savedPlayRate = parseFloat(playRateStr);
    if (playRateStr && savedPlayRate >= 0) {
      return savedPlayRate;
    } else {
      return 1;
    }
  } catch (e) {
    return 1;
  }
};

const saveRate = (newPlayRate) => {
  localStorage.setItem(PLAY_RATE_KEY, newPlayRate);
};

const Player = ({ msgId, link, cues = [], emitter, bottomLine }) => {
  const playerRef = useRef();

  const [play, setPlay] = useState(false);
  const [range, setRange] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [playRate, setPlayRate] = useState(1);

  useEffect(() => {
    setPlayRate(getSavedRate());
  }, []);

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.playbackRate = playRate;
    }
  }, [playerRef, playRate]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.onloadedmetadata = function () {
        setMaxValue(
          Math.round((playerRef?.current?.duration || 0) * 100) / 100,
        );
      };
      playerRef.current.onended = function () {
        setPlay(false);
        setRange(0);
      };
      const track = playerRef.current.addTextTrack('metadata');

      cues.forEach((c) => {
        track.addCue(c);
      });

      const subscription = emitter.addListener('playStart', (start) => {
        if (start >= 0) playerRef.current.currentTime = start;
        setPlay(true);
        playerRef.current.play();
      });

      return () => {
        subscription.remove();
      };
    }
  }, [link, cues, emitter]);

  useEffect(() => {
    const subscription = eventEmitter.addListener('onAudioPlay', (id) => {
      if (id !== msgId) {
        stop_aud();
      }
    });
    return () => {
      subscription.remove();
    };
  }, [msgId]);

  useEffect(() => {
    if (play) {
      const interval = setInterval(() => {
        setRange(Math.round(playerRef.current.currentTime * 100) / 100);
      }, 300);
      return () => {
        clearInterval(interval);
      };
    }
  }, [play]);

  const play_aud = () => {
    eventEmitter.emit('onAudioPlay', msgId);
    setPlay(true);
    playerRef.current.play();
  };

  const setTime = (pos) => {
    pos = Math.round(pos * 100) / 100;
    console.log('setTime', pos);
    playerRef.current.currentTime = pos;
    setRange(pos);
  };

  const stop_aud = () => {
    setPlay(false);
    playerRef.current.pause();
  };

  const startStop = () => {
    play ? stop_aud() : play_aud();
  };

  const padNum = (n) => (n || '0').toString().padStart(2, '0');

  const formatLabel = (_value) => {
    const value = Math.floor(_value);
    let rest = value;
    const secInM = 60;
    const secInH = secInM * 60;
    let h, m, s;
    if (rest % secInH < rest) {
      h = Math.floor(rest / secInH);
      rest = rest % secInH;
    }
    if (rest % secInM < rest) {
      m = Math.floor(rest / secInM);
      rest = rest % secInM;
    }
    s = rest;

    return [h ? padNum(h) : '', padNum(m), padNum(s)]
      .filter((el) => el)
      .join(':');
  };

  return (
    <div id="wrapper" className={bottomLine ? '' : ''}>
      <div className="player">
        <audio id="music_player" ref={playerRef}>
          {link ? <source src={link} /> : null}
        </audio>
        <div className={'slidecontainer'}>
          <div style={{ height: '15px' }}></div>
          <PlayerRange
            maxValue={maxValue || 1}
            minValue={0}
            step={0.01}
            value={range}
            onChange={setTime}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'space-between',
              justifyContent: 'space-between',
            }}>
            <div className="player_label">{formatLabel(range)}</div>
            <div className="player_label">{formatLabel(maxValue)}</div>
          </div>
        </div>
        <div className={'playStopCont'}>
          <Box css={{ cursor: 'pointer' }}>
            <img src={center} alt="" />
          </Box>

          <img
            className="player_btn"
            onClick={startStop}
            width="60px"
            height="60px"
            type="image"
            alt=""
            src={play ? playerPause : playerPlay}
            id="play_button"
          />
          <Box
            width="40px"
            height="40px"
            display="flex"
            flexDirection="column"
            css={{ cursor: 'pointer' }}
            onClick={() => {
              const playRateIndex = playRates.indexOf(playRate);

              const newPlayRateIndex =
                playRateIndex + 1 >= playRates.length ? 0 : playRateIndex + 1;
              const newPlayRate = playRates[newPlayRateIndex];
              saveRate(newPlayRate);
              setPlayRate(newPlayRate);
            }}>
            <Box
              fontStyle="normal"
              fontWeight="bold"
              fontSize="20px"
              lineHeight="22px"
              textAlign="center"
              letterSpacing="0.3px"
              color="#7674E2">
              {playRate + 'x'}
            </Box>
            <Box
              fontStyle="normal"
              fontWeight="600"
              fontSize="11px"
              lineHeight="22px"
              letterSpacing="0.3px"
              color="#7674E2">
              Speed
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default Player;
