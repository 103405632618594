import React, { useEffect, useRef } from 'react';
// import BlurbEditor from '../../components/BlurbEditor';
import Word from './Word';

const CueText = ({ pCues, eventEmitter, editing, onChangeData }) => {
  const textRef = useRef();

  const onCopy = (event) => {
    const selection = document.getSelection();
    event.clipboardData.setData(
      'text/plain',
      selection.toString().split('\n').join(' '),
    );
    event.preventDefault();
  };

  useEffect(() => {
    if (textRef && textRef.current) {
      const current = textRef.current;
      current.addEventListener('copy', onCopy);
      return () => {
        current.removeEventListener('copy', onCopy);
      };
    }
  }, [textRef]);

  return (
    <div>
      <div
        ref={textRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 100,
        }}>
        {editing
          ? null // <BlurbEditor pCues={pCues} onChangeData={onChangeData} />
          : pCues.map((paragraph, i) => (
              <div
                key={i}
                className={'paragraph'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}>
                {paragraph.map((e, npp) => (
                  <Word
                    key={`${e.id}-${npp}`}
                    e={e}
                    eventEmitter={eventEmitter}
                  />
                ))}
              </div>
            ))}
      </div>
    </div>
  );
};

export default CueText;
