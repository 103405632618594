import styled from 'styled-components';
import {
  flexbox,
  position,
  layout,
  space,
  color,
  border,
  shadow,
  typography,
} from 'styled-system';

export const BoxHoc = (elm) =>
  styled(elm)(
    {
      boxSizing: 'border-box',
    },
    flexbox,
    position,
    layout,
    space,
    color,
    border,
    shadow,
    typography,
    (props) => props.css,
  );

const Box = BoxHoc('div');

export default Box;
