import React, { useEffect, useState } from 'react';
import { firebase } from '../firebase';
import './MsgPhoto.css';

const storageRef = firebase.storage().ref();

const MsgVideo = ({ msg, roomId, visible }) => {
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState(false);
  const [preview, setPreview] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [previewError, setPreviewError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (visible && !preview && msg.preview) {
        const dotOUT = '.JPG';
        const thumbTail = '_thumbnail' + dotOUT;
        const pUrl = 'sounds/' + roomId + '/' + msg.id + thumbTail;
        await storageRef
          .child(pUrl)
          .getDownloadURL()
          .then(link => {
            setPreview(link);
          })
          .catch(e => {
            setPreviewError(true);
          });
      }

      if (visible && !link) {
        const videoUrl = 'sounds/' + roomId + '/' + msg.url;
        await storageRef
          .child(videoUrl)
          .getDownloadURL()
          .then(link => {
            setLink(link);
          })
          .catch(e => {
            setLinkError(true);
          });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, msg]);

  return (
    <div>
      {link ? (
        <video
          className="photoImg MsgWithRadius"
          id={msg.id}
          src={link}
          poster={preview}
          controls
        />
      ) : linkError ? (
        <div className="photoImg photoWidth MsgWithRadius">
          Video unavailible
        </div>
      ) : (
        <div className="photoImg photoWidth MsgWithRadius">Loading...</div>
      )}
    </div>
  );
};

export default MsgVideo;
