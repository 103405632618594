import { useState, useContext } from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { firebase } from '../firebase';
import ExportModalContext from './../contexts/ExportModalContext';
import arrowBack from 'img/arrow_back_gray.svg';

const logOut = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      // Sign-out successful.
    })
    .catch(function (error) {
      // An error happened.
    });
};

const TextInput = styled.input`
  font-family: 'Proxima Nova';
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* or 122% */

  align-items: center;

  color: #2f374d;

  &::placeholder {
    color: #c0c0cc;
    font-size: 33px;
    line-height: 45px;
    font-weight: 800;
  }
`;

const FieldInput = ({ value, placeholder, onChange, onBlur }) => {
  return (
    <Box
      marginTop="20px"
      borderBottom="1px solid #E1E0E0"
      display="flex"
      flexDirection="column"
      alignItems={'flex-start'}>
      <Box
        fontSize="12px"
        lineHeight="15px"
        /* identical to box height, or 125% */

        /* Text */

        color="#2F374D">
        {placeholder}
      </Box>
      <TextInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type="text"
        placeholder={''}
      />
    </Box>
  );
};

const ProfileMenu = ({ user, account, left, back }) => {
  const appExportModal = useContext(ExportModalContext);

  const phone = user && user.phoneNumber;
  const accountRef = firebase
    .database()
    .ref('profiles')
    .child(phone)
    .child('account');

  const [name, setName] = useState(account.name || '');
  const onNameChange = (n) => {
    setName(n);
  };
  const onBlur = (n) => {
    setName(n);
    accountRef.update({ name: n });
  };

  const exportData = async () => {
    console.log('exportData');
    appExportModal.toggle(true);
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      backgroundColor={'#FFFFFF'}
      position="fixed"
      top="0"
      bottom="0"
      left={left || '0'}
      right="0">
      <Box display="flex" flex={1} flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="65px"
          paddingX="21px"
          alignItems="center"
          backgroundColor={'white'}>
          <Box
            onClick={back}
            css={{ cursor: 'pointer' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="65px"
            width="65px">
            <img src={arrowBack} alt="" />
          </Box>
          <Box fontWeight="600" fontSize="17px">
            Profile Settings
          </Box>
          <Box height="65px" width="65px" />
        </Box>
        <Box
          display="flex"
          paddingX="21px"
          flex={1}
          flexDirection="column"
          overflowY="auto"
          overflowX="hidden"
          scrollbarWidth="none"
          backgroundColor={'#F9F9FA'}>
          <FieldInput
            value={name}
            onChange={(event) => onNameChange(event.target.value)}
            onBlur={(event) => onBlur(event.target.value)}
            placeholder="Name"
          />
          <Box
            onClick={logOut}
            display="flex"
            height="58px"
            fontWeight="600"
            fontSize="18px"
            color="#EE5656"
            alignItems="center"
            marginTop="32px"
            css={{ cursor: 'pointer' }}>
            Sign Out
          </Box>
          <Box
            onClick={exportData}
            display="flex"
            height="58px"
            fontWeight="600"
            fontSize="18px"
            color="#2F374D"
            alignItems="center"
            css={{ cursor: 'pointer' }}>
            Export Data
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileMenu;
