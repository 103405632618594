import React from 'react';
import './MsgPhoto.css';

const MsgGps = ({ msg, roomId, visible }) => {
  return (
    <div>
      <img
        alt=""
        className="photoImg MsgWithRadius"
        id={msg.id}
        src={msg.url}
      />
    </div>
  );
};

export default MsgGps;
