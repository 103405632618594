import { useEffect, useState, useMemo, useRef } from 'react';
import pako from 'pako';
import { firebase } from '../firebase';
import Player from './Player';
import Box, { BoxHoc } from 'components/Box';
import CueText from 'components/CueText';
import { EventEmitter } from 'fbemitter';
import arrowBack from 'img/arrow_back_ios_24px.svg';
import downloading from 'img/download.gif';
import share from 'img/share.svg';
import { nativeShare } from 'utils/shareUtils';
import { formatAppintmentTs } from 'utils/dateUtils';

const storageRef = firebase.storage().ref();

const InputBox = BoxHoc('textarea');

const getSec = (tm) => {
  if (!tm) return 0;
  if (typeof tm === 'string') {
    return parseFloat(tm.replace('s', ''));
  }
  return parseFloat((tm.seconds || 0) + '.' + tm.nanos);
};

export const parseCues = (item) => {
  const arr = [];
  const allParagraphs = [];
  const tran = item || {};
  const results = tran.results || [];
  results.forEach((result) => {
    const alternatives = result.alternatives[0] || [];
    const words = alternatives.words || [];
    const paragraph = [];
    words
      .filter((w) => w)
      .forEach((word) => {
        const startSec = getSec(word.startTime);
        const endSec = getSec(word.endTime);
        const vttCue = new VTTCue(startSec, endSec, word.word);

        vttCue.id = [word.word, startSec, endSec].join('-');
        arr.push(vttCue);
        paragraph.push(vttCue);
      });
    if (paragraph.length) {
      allParagraphs.push(paragraph);
    }
  });
  return [arr, allParagraphs];
};

const TranscriptionScreen = ({
  roomId,
  msgId,
  msg,
  appointment,
  back,
  left,
  isMy,
}) => {
  const [link, setLink] = useState('');
  const [tran, setTran] = useState(null);
  const [title, setTitle] = useState(appointment?.title || '');
  const renameRef = useRef();
  const eventEmitter = useMemo(() => new EventEmitter(), []);

  useEffect(() => {
    if (renameRef.current) {
      const e = renameRef.current;
      e.style.height = 'inherit';
      e.style.height = `${e.scrollHeight}px`;
    }
  }, [renameRef]);

  useEffect(() => {
    if (!link && roomId && msgId) {
      const audioUrl = 'sounds/' + roomId + '/' + msgId + '.MP3';
      storageRef
        .child(audioUrl)
        .getDownloadURL()
        .then((link) => {
          setLink(link);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [link, roomId, msgId]);

  useEffect(() => {
    if (!(roomId && msgId)) return;
    if (msg.noResults) {
      setTran({});
      return;
    }
    const jsonGzRef = storageRef.child(`sounds/${roomId}/${msgId}.json.gz`);
    jsonGzRef
      .getDownloadURL()
      .then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const json = JSON.parse(
                pako.inflate(e.target.result, { to: 'string' }),
              );
              setTran(json);
              console.log(json);
            } catch (err) {
              console.log(err);
            }
          };
          reader.readAsArrayBuffer(blob);
          /*
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = `${msgId}.json.gz`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          */
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            console.error(error.code);
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            console.error(error.code);
            break;
          case 'storage/canceled':
            // User canceled the upload
            console.error(error.code);
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            console.error(error.code);
            break;
          default:
            console.error(error.code);
        }
      });
  }, [roomId, msgId, msg.noResults]);

  const [cues, pCues] = parseCues(tran);

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      backgroundColor={'#FFFFFF'}
      position="fixed"
      top="0"
      bottom="0"
      left={left || '0'}
      right="0">
      <Box display="flex" flex={1} flexDirection="column" maxWidth="680px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="64px"
          paddingX="21px"
          alignItems="center">
          <Box
            onClick={back}
            css={{ cursor: 'pointer' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="26px"
            width="68px">
            <img src={arrowBack} alt="" />
            <Box>Back</Box>
          </Box>
          <Box
            css={{ cursor: 'pointer' }}
            onClick={() => {
              const url = `${window.location.origin}/preview?roomID=${roomId}&msgID=${msgId}`;
              nativeShare(url);
            }}>
            <img src={share} alt="" />
          </Box>
        </Box>
        <Box
          display="flex"
          paddingX="21px"
          flex={1}
          flexDirection="column"
          overflowY="auto"
          overflowX="hidden"
          scrollbarWidth="none">
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              paddingTop="10px"
              paddingBottom="24px">
              <InputBox
                display={appointment ? 'block' : 'none'}
                ref={renameRef}
                type="text"
                fontFamily="Proxima Nova"
                wordBreak="break-word"
                width="100%"
                fontStyle="normal"
                fontSize="28px"
                fontWeight="800"
                lineHeight="135%"
                color="#2f374d"
                css={{ caretColor: '#7674E2', resize: 'none' }}
                backgroundColor="transparent"
                border="none"
                value={title}
                rows={1}
                spellCheck={false}
                onKeyDown={(e) => {
                  e.target.style.height = 'inherit';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onChange={(e) => {
                  e.target.style.height = 'inherit';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                  setTitle(e.target.value);
                }}
                onBlur={async (e) => {
                  if (appointment.id) {
                    const appointmentRef = firebase
                      .database()
                      .ref('rooms')
                      .child(roomId)
                      .child('appointments')
                      .child(appointment.id);
                    const appointmentSnap = await appointmentRef.once('value');
                    if (appointmentSnap.exists()) {
                      appointmentRef.update({
                        title: title,
                      });
                    }
                  }
                }}
              />
              <Box
                marginTop="8px"
                fontSize="14px"
                lineHeight="135%"
                color="#2f374d">
                {formatAppintmentTs(appointment?.appointmentTimestamp)}
              </Box>
            </Box>
          </Box>

          {tran ? (
            <CueText
              pCues={pCues}
              eventEmitter={eventEmitter}
              editing={false}
            />
          ) : (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <img src={downloading} alt="" />
            </Box>
          )}
        </Box>
        <Box width="100%" paddingX="21px" paddingBottom="10px">
          {link ? (
            <Player
              msgId={msgId}
              link={link}
              cues={cues}
              emitter={eventEmitter}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default TranscriptionScreen;
