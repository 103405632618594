import React, { useState } from 'react';
import { FullModal } from './Modal';
import styled from 'styled-components';
import useWindowsSize from './../hooks/WindowSize';
import close from './../img/modal_close2.svg';
import exportFrame from './../img/exportFrame.png';
import { firebase } from '../firebase';
import { validateEmail } from './FillAccount';

const Column = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f7;
  padding: 20px;
  border-radius: 12px;
  max-width: 590px;
`;

const Submit = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 62px;

  background: #7674e2;
  border-radius: 8px;

  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

const CloseContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-end;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalTextHeader = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 33px;
  line-height: 46px;
  /* or 139% */

  text-align: center;

  color: #2f374d;

  margin-top: 25px;
`;

const ModalText = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  text-align: center;
  align-self: center;

  /* Text */

  color: #2f374d;

  margin-top: 6px;
  margin-bottom: 30px;
  max-width: 600px;
`;

const ModalTextSpan = styled.span`
  font-weight: bold;
`;

const ImgDivider = styled.div`
  width: 24px;
  height: 24px;
`;

const ModalIcon = styled.img`
  width: 49px;
  height: 49px;
  align-self: center;
`;

const EmailInput = styled.input`
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  border: ${(props) =>
    props.error ? '1px solid #ff0000' : '1px solid transparent'};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 8px;

  /* Meta */

  color: #979dad;
`;

export const useAppExportModal = () => {
  const [isOpen, toggle] = useState(false);
  return { isOpen, toggle };
};

const AppExportModal = ({ user, isOpen, toggle }) => {
  const phone = user && user.phoneNumber;
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const exportData = async (email) => {
    const exportRef = firebase
      .database()
      .ref('exports')
      .child(phone)
      .child(`${Date.now()}`);
    await exportRef.update({
      status: 'need',
      email: email,
    });
  };

  const { width } = useWindowsSize();
  const isMobile = width < 768;
  const ButtonsContainer = isMobile ? CenterColumn : CenterRow;
  return (
    <FullModal isOpen={isOpen} toggle={toggle}>
      <Column>
        <CloseContainer>
          <img alt="" src={close} onClick={() => toggle()} />
        </CloseContainer>
        <img
          style={{ alignSelf: 'center' }}
          width={'130px'}
          height={'130px'}
          alt=""
          src={exportFrame}
        />
        <ModalTextHeader>
          Enter your email and we’ll send you a link when your download is
          ready.
        </ModalTextHeader>
        <ModalText>
          Compiling all the recordings, mesages, images and videos associated
          with your account may take about 2-3 minutes.
        </ModalText>
        <ButtonsContainer>
          <EmailInput
            error={error}
            className="fullWidth"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setError(false);
            }}
            placeholder="Email Address"
          />
          <ImgDivider />
          <Submit
            onClick={async () => {
              if (validateEmail(email)) {
                await exportData(email);
                toggle();
              } else {
                setError(true);
              }
            }}>
            Submit
          </Submit>
        </ButtonsContainer>
      </Column>
    </FullModal>
  );
};

export default AppExportModal;
