const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const isSameDay = (d1, d2) => {
  return isSameMonth(d1, d2) && d1.getDate() === d2.getDate();
};

const isSameMonth = (d1, d2) => {
  return isSameYear(d1, d2) && d1.getMonth() === d2.getMonth();
};

const isSameYear = (d1, d2) => {
  return d1.getFullYear() === d2.getFullYear();
};

const isSameYearDay = (d1, d2) => {
  return isSameYear(d1, d2) && isSameMonth(d1, d2) && isSameDay(d1, d2);
};

const isSubDay = (date, num) => {
  const targetDay = new Date();
  targetDay.setDate(targetDay.getDate() + num);
  return isSameDay(date, targetDay);
};

const formatMenuDate = (date) => {
  const now = new Date();
  const dateDiffInMilliseconds = now - date;
  const dateDiffInSeconds = dateDiffInMilliseconds / 1000;
  if (dateDiffInSeconds < 60) {
    return 'just now';
  } else if (dateDiffInSeconds < 60 * 60) {
    return '' + Math.round(dateDiffInSeconds / 60) + ' min ago';
  } else if (isSubDay(date, 0)) {
    return formatAMPM(date);
  } else if (isSubDay(date, -1)) {
    return 'yesterday at ' + formatAMPM(date);
  } else if (isSameYear(now, date)) {
    return formatDM(date);
  } else return formatDMY(date);
};

const parseDMY = (date) => {
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  return {
    D: day,
    M: monthIndex,
    Y: year,
  };
};

const formatDMY = (date) => {
  const { D, M, Y } = parseDMY(date);
  return monthNames[M] + ' ' + D + ' ' + Y;
};

const formatDM = (date) => {
  const { D, M } = parseDMY(date);
  return monthNames[M] + ' ' + D;
};

const formatDate = (date) => {
  if (isSubDay(date, 0)) {
    return 'Today';
  }

  if (isSubDay(date, -1)) {
    return 'Yesterday';
  }

  return formatDMY(date);
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const formatAppintmentTs = (ts) => {
  if (!ts) return '';
  const date = new Date(ts);
  const { Y } = parseDMY(date);
  return formatDM(date) + ', ' + Y + ' at ' + formatAMPM(date);
};

export {
  formatDate,
  formatMenuDate,
  formatAMPM,
  isSameYearDay,
  formatAppintmentTs,
};
