import React from 'react';
import './MsgText.css';
import personAdd from './../img/person_add.svg';

const MsgWelcome = ({ name, addMember }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        boxSizing: 'border-box',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}>
      <div style={{ fontSize: '28px' }}>👋</div>
      <div
        style={{
          fontWeight: 800,
          fontSize: '24px',
          color: '#2F374D',
          marginTop: '12px',
        }}>
        {`Welcome${name ? ' ' + name : ''}!`}
      </div>
      <div
        style={{
          fontSize: '16px',
          textAlign: 'center',
          color: '#677086',
          marginTop: '6px',
        }}>
        Add your support network of family to share and discuss your recordings.
      </div>
      <div
        onClick={addMember}
        style={{
          width: '170px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          border: '1px solid #7674E2',
          boxSizing: 'border-box',
          borderRadius: '50px',
          cursor: 'pointer',
          marginTop: '12px',
        }}>
        <img src={personAdd} alt="" />
        <div
          style={{
            color: '#7674E2',
            fontWeight: 'bold',
            fontSize: '16px',
            marginLeft: '13px',
          }}>
          Invite Family
        </div>
      </div>
      <div
        style={{
          height: '1px',
          backgroundColor: '#D1D1D1',
          marginTop: '28px',
          width: '100%',
        }}
      />
    </div>
  );
};

export default MsgWelcome;
