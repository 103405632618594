import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

const configStr = process.env.REACT_APP_CONFIG_JSON;

const config = JSON.parse(configStr);

const firebaseEmulators =
  process.env.REACT_APP_EMULATORS &&
  JSON.parse(process.env.REACT_APP_EMULATORS);

const projectId = config.projectId;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  if (firebaseEmulators) {
    console.log('Automatically connecting Firebase SDKs to running emulators:');
    Object.keys(firebaseEmulators).forEach(function (key) {
      console.log(
        '\t' +
          key +
          ': http://' +
          firebaseEmulators[key].host +
          ':' +
          firebaseEmulators[key].port,
      );
    });

    if (firebaseEmulators.database && typeof firebase.database === 'function') {
      firebase
        .database()
        .useEmulator(
          firebaseEmulators.database.host,
          firebaseEmulators.database.port,
        );
    }

    if (
      firebaseEmulators.firestore &&
      typeof firebase.firestore === 'function'
    ) {
      firebase
        .firestore()
        .useEmulator(
          firebaseEmulators.firestore.host,
          firebaseEmulators.firestore.port,
        );
    }

    if (
      firebaseEmulators.functions &&
      typeof firebase.functions === 'function'
    ) {
      firebase
        .functions()
        .useEmulator(
          firebaseEmulators.functions.host,
          firebaseEmulators.functions.port,
        );
    }

    if (firebaseEmulators.auth && typeof firebase.auth === 'function') {
      firebase
        .auth()
        .useEmulator(
          'http://' +
            firebaseEmulators.auth.host +
            ':' +
            firebaseEmulators.auth.port,
        );
    }
  } else {
    console.log(
      "To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html",
    );
  }
}

firebase.auth().languageCode = 'us';

/*
window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  'sign-in-button',
  {
    size: 'invisible',
    callback: function(response) {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      //onSignInSubmit();
      alert('reCAPTCHA solved');
    },
  }
);
*/

/*
window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  'recaptcha-container'
);

window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  'recaptcha-container',
  {
    size: 'normal',
    callback: function(response) {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // ...
    },
    'expired-callback': function() {
      // Response expired. Ask user to solve reCAPTCHA again.
      // ...
    },
  }
);
*/
export { firebase, projectId };
