import React, { Fragment } from 'react';

import { useEffect } from 'react';
import Message from './Message';
import './Messages.css';
import MsgWelcome from './MsgWelcome';
import { formatDate, isSameYearDay } from './../utils/dateUtils';

const messagesTypes = ['Text', 'Audio', 'Photo', 'Video', 'Gps'];

const checkIfMediaReady = (msg) => {
  if (msg.type === 'Photo' || msg.type === 'Video') {
    return msg.fileStatus === 'OK';
  }
  if (msg.type === 'Audio') {
    return msg.fileStatus === 'OK' && msg.mp3Status === 'OK';
  }
  return true;
};

const sortFn = (a, b) => {
  if (a.type === 'ROOM_CREATED') return -1;
  if (b.type === 'ROOM_CREATED') return 1;
  return a.timestamp > b.timestamp ? 1 : -1;
};

const Messages = (props) => {
  const room = props.room || {};
  const membersInfo = room.membersInfo || {};
  const gotoBottom = props.gotoBottom;
  const name = props.name;
  const addMember = props.addMember;
  const onTranscriptionOpen = props.onTranscriptionOpen;
  const messages = room.messages || {};
  const appointments = room.appointments || {};
  const msgIds = Object.keys(messages);
  const renderMsgs = msgIds
    .map((id) => {
      return messages[id];
    })
    .filter((msg) => messagesTypes.includes(msg.type))
    .filter((msg) => msg.timestamp > 0)
    .filter((msg) => checkIfMediaReady(msg))
    .sort(sortFn);

  useEffect(() => {
    const lastMsg = renderMsgs.slice(-1)[0] || {};
    const lastID = lastMsg.id;
    if (lastID) {
      gotoBottom('msg-' + lastID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderMsgs.length]);

  let prevUser = '';
  let prevDate = new Date(0);

  if (renderMsgs.length > 0) {
    return (
      <Fragment>
        <MsgWelcome name={name} addMember={addMember} />
        {renderMsgs.map((message) => {
          const pU = prevUser;
          const pD = prevDate;
          prevUser = message.user;
          prevDate = new Date(message.timestamp);
          const needDate = pD !== prevDate && !isSameYearDay(pD, prevDate);
          const memberInfo = membersInfo[message.user];

          const hideUser = !needDate && pU === message.user;
          const appointment = Object.keys(appointments)
            .map((appointmentId) => appointments[appointmentId])
            .find((appointment) => {
              return appointment.audioId === message.id;
            });
          return (
            <div
              onClick={() => {
                onTranscriptionOpen && onTranscriptionOpen(message.id);
              }}
              key={message.id}
              style={{ display: 'flex', flexDirection: 'column' }}>
              {needDate ? (
                <div>
                  <div className="dateHeader"> {formatDate(prevDate)} </div>
                </div>
              ) : null}
              <Message
                key={message.id}
                msg={message}
                appointment={appointment}
                memberInfo={memberInfo}
                roomId={room.id}
                hideHeader={hideUser}
              />
            </div>
          );
        })}
      </Fragment>
    );
  } else {
    return (
      <div className="noMessages">
        <div>No messages</div>
      </div>
    );
  }
};

export default Messages;
