import { firebase } from '../firebase';

const getDefMesage = (msgId, userId) => {
  const tm = new Date();
  const tmStr = tm.toJSON();
  return {
    id: msgId,
    address: '',
    downloadURL: '',
    fileStatus: '',
    time: tmStr,
    user: userId,
  };
};

const deleteMessage = (msgId, roomId) => {
  const msgUrl = 'rooms/' + roomId + '/messages/' + msgId;
  firebase
    .database()
    .ref(msgUrl)
    .remove();
};

const addTextMessage = (msgId, roomId, userId, text) => {
  const defMsg = getDefMesage(msgId, userId);
  const obj = {
    ...defMsg,
    type: 'Text',
    text: text,
  };
  const msgUrl = 'rooms/' + roomId + '/messages/' + msgId;

  firebase
    .database()
    .ref(msgUrl)
    .update(obj);
};

export default { addTextMessage, deleteMessage };
