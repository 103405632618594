import React, { useContext } from 'react';
import RoomUsers from './RoomUsers';
import './RoomSettings.css';
import DownloadModalContext from './../contexts/DownloadModalContext';

import plusGreen from './../img/plusGreen.svg';

const RoomSettings = ({ room }) => {
  const appDownloadModal = useContext(DownloadModalContext);
  const authorizedUsers = room.authorizedUsers || {};
  const usersCount = Object.keys(authorizedUsers).length;
  return (
    <div className="roomsSettingsContainer">
      <div className="roomSettingsUsers">
        <div>
          <div className="roomsUserItem" onClick={appDownloadModal.toggle}>
            <img src={plusGreen} alt="" />
            <div style={{ width: '11px' }} />
            <div className="roomsUserName" style={{ fontWeight: 'bold' }}>
              {'Invite Member'}
            </div>
          </div>
          <div className="membersCount">MEMBERS • {usersCount}</div>
          <RoomUsers room={room} />
        </div>
      </div>
    </div>
  );
};

export default RoomSettings;
