import React from 'react';
import './Preview.css';
import './Menu.css';
import { withRouter } from 'react-router-dom';

import { firebase } from '../firebase';
import { useEffect, useState, useCallback } from 'react';
import Messages from './Messages';
import RoomsList from './RoomsList';
import Composer from './Composer';
import RoomSettings from './RoomSettings';
import RecordingScreen from './RecordingScreen';
import TranscriptionScreen from './TranscriptionScreen';
import DownloadModal, { useDownloadModal } from './DownloadModal';
import MsgSettingsModal, { useMsgSettingsModal } from './MsgSettingsModal';
import AppExportModal, { useAppExportModal } from './AppExportModal';
import DownloadModalContext from './../contexts/DownloadModalContext';
import MsgSettingsModalContext from './../contexts/MsgSettingsModalContext';
import WinSizeContext from './../contexts/WinSizeContext';

import useWindowsSize from './../hooks/WindowSize';
import FillAccount from './FillAccount';

import { gql, useMutation } from '@apollo/client';

import dts from './../img/more_vert_24px_outlined.svg';
import menu from './../img/menu.svg';
import arrow_back from './../img/arrow_back.svg';
import close from './../img/close.svg';
import profile from 'img/profile.svg';
import help from 'img/help.svg';
import Box from 'components/Box';
import ProfileMenu from 'components/ProfileMenu';
import HelpMenu from 'components/HelpMenu';

import { useRecording } from 'hooks';
import { nativeShare } from 'utils/shareUtils';
import { v4 as uuidv4 } from 'uuid';
import ExportModalContext from 'contexts/ExportModalContext';

const MenuBottomBtn = ({ pic, text, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="flex-start"
      height="58px"
      borderTop="1px solid rgba(255, 255, 255, 0.2)"
      paddingX="17px"
      css={{ cursor: 'pointer' }}
      onClick={onClick}>
      <Box display="flex" flexDirection="row">
        <img src={pic} alt="" />
        <Box paddingLeft="14px" fontSize="17px" color="#FFFFFF">
          {text}
        </Box>
      </Box>
    </Box>
  );
};

const PreviewPage = ({
  history,
  user,
  directRoom,
  directMsg,
  directCode,
  directRecord,
  directExport,
}) => (
  <div>
    <Preview
      history={history}
      user={user}
      directRoom={directRoom}
      directMsg={directMsg}
      directCode={directCode}
      directRecord={directRecord}
      directExport={directExport}
    />
  </div>
);

let roomsStore = {};

const useUser = (user = {}) => {
  const _user = user || {};
  const [account, setAccount] = useState(undefined);
  useEffect(() => {
    if (_user.phoneNumber) {
      const phone = _user.phoneNumber;
      const accountRef = firebase
        .database()
        .ref('profiles')
        .child(phone)
        .child('account');
      accountRef.on('value', (snapshot) => {
        const accountVal = snapshot.val() || {};
        setAccount(accountVal);
      });

      return () => {
        accountRef.off();
      };
    }
  }, [_user.phoneNumber]);
  return { account };
};

const useCurrentRoom = (user, rooms, directRoom, directCode) => {
  const [curRoom, setCurRoomState] = useState('');
  const getStorePath = () => user.phoneNumber + '/currentroom';
  const setCurRoom = (r) => {
    console.log('setCurRoom', r);
    setCurRoomState(r);
    try {
      localStorage.setItem(getStorePath(), r);
    } catch (e) {}
  };
  useEffect(() => {
    if (!user || !rooms) {
      setCurRoomState('');
      return;
    }
    const savedRoom = directRoom || localStorage.getItem(getStorePath());
    console.log('savedRoom', savedRoom);
    const allRoomKeys = Object.keys(rooms || []);
    const roomKeys = allRoomKeys.filter((k) => rooms[k]);
    if (!roomKeys.length) {
      setCurRoomState('');
      return;
    }
    if (savedRoom && rooms[savedRoom]) {
      setCurRoomState(savedRoom);
      console.log('setSavedCurRoom', savedRoom);
      return;
    }
    if (!curRoom || !roomKeys.includes(curRoom)) {
      const id = roomKeys[0];
      setCurRoomState(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, rooms]);
  return { curRoomID: curRoom, setCurRoom };
};

const useRooms = (user) => {
  // eslint-disable-next-line no-unused-vars
  const [roomGrants, setGrants] = useState({});
  const [rooms, setRooms] = useState({});
  const roomRefs = {};

  useEffect(() => {
    if (!user) return;
    const phoneNumber = user.phoneNumber;
    const grantsRef = firebase.database().ref('roomGrants/' + phoneNumber);
    grantsRef.on('value', (snapshot) => {
      const grants = snapshot.val() || {};
      const newRoomsStore = {};
      Object.keys(grants).forEach((g) => {
        if (!roomsStore[g]) {
          roomsStore[g] = {};
        }
      });
      Object.keys(roomsStore).forEach((key) => {
        if (grants[key]) {
          newRoomsStore[key] = roomsStore[key];
        }
      });
      roomsStore = { ...newRoomsStore };
      setRooms(roomsStore);
      setGrants(grants);
      Object.keys(grants).forEach((key) => {
        if (roomRefs[key]) {
          roomRefs[key].off();
        }
        if (grants[key] === true) {
          roomRefs[key] = firebase.database().ref('/rooms/' + key);
          roomRefs[key].on('value', (snapshot) => {
            var room = snapshot.val();
            //fix android client number => id
            room.id = room.id || room.number;
            console.log('room', key, room.name);
            var _rooms = {};
            _rooms[key] = room;
            roomsStore = { ...roomsStore, ..._rooms };
            setRooms({ ...roomsStore });
          });
        }
      });
    });

    return () => {
      if (grantsRef) {
        grantsRef.off();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { roomGrants, rooms };
};

const CREATE_INVITE_CODE = gql`
  mutation createInviteCode($roomId: ID!) {
    createInviteCode(roomId: $roomId)
  }
`;

const APPLY_INVITE_CODE = gql`
  mutation applyInviteCode($code: ID!) {
    applyInviteCode(code: $code)
  }
`;

const useDirectCode = (directCode, curRoomID) => {
  const [_createCode] = useMutation(CREATE_INVITE_CODE, {
    onCompleted: (data) => {
      if (data && data.createInviteCode) {
        const url = `${window.location.origin}/preview?roomID=${curRoomID}&inviteCode=${data.createInviteCode}`;
        nativeShare(url);
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [_applyCode] = useMutation(APPLY_INVITE_CODE, {
    onCompleted: (data) => {
      if (data && data.applyInviteCode) {
        console.log('APPLY_INVITE_CODE');
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const createCode = useCallback(
    (roomId) => {
      _createCode({
        variables: {
          roomId: roomId,
        },
      });
    },
    [_createCode],
  );

  const applyCode = useCallback(
    (code) => {
      _applyCode({
        variables: {
          code: code,
        },
      });
    },
    [_applyCode],
  );

  useEffect(() => {
    if (directCode) {
      console.log('applyCode', directCode);
      applyCode(directCode);
    }
  }, [directCode, applyCode]);

  return { createCode, applyCode };
};

const Preview = (props) => {
  const [leftMenu, setLeftMenu] = useState(false);
  const [rightMenu, setRightMenu] = useState(false);
  const [skipMail, setSkipMail] = useState(false);
  const appDownloadModal = useDownloadModal();
  const appMsgSettingsModal = useMsgSettingsModal();
  const appExportModal = useAppExportModal();
  const winSize = useWindowsSize();
  const {
    user,
    directRoom,
    directMsg,
    directCode,
    directRecord,
    directExport,
  } = props;
  const [directRecording, setDirectRecording] = useState(!!directRecord);
  const { account } = useUser(user);
  const [uploading, setUploading] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [helpMenu, setHelpMenu] = useState(false);
  const [exporting, setExporting] = useState(!!directExport);

  const openProfile = () => {
    setProfileMenu(true);
    setHelpMenu(false);
  };

  const openHelp = () => {
    setProfileMenu(false);
    setHelpMenu(true);
  };

  const { roomGrants, rooms } = useRooms(user);
  const { curRoomID, setCurRoom } = useCurrentRoom(
    user,
    roomGrants,
    directRoom,
    directCode,
  );
  const { createCode } = useDirectCode(directCode, curRoomID);

  const {
    recording: audioRecording,
    finishing: audioFinishing,
    recordingStart: audioRecordingStart,
    startRecord: audioStartRecord,
    stopRecord: audioStopRecord,
  } = useRecording({
    onError: (text) => {
      alert(text);
    },
    onEmptyAudio: (recordID) => {
      alert('onEmptyAudio');
    },
    logEvent: console.log,
    type: 'MEDCORDER',
  });

  const curRoom = rooms[curRoomID] || {};
  const appointments = curRoom.appointments || {};
  const [transcriptionMsg, setTranscriptionMsg] = useState(directMsg);
  const curMsgInRoom = transcriptionMsg
    ? curRoom?.['messages']?.[transcriptionMsg]
    : null;
  const appointment = Object.keys(appointments)
    .map((appointmentId) => appointments[appointmentId])
    .find((appointment) => {
      return appointment.audioId === transcriptionMsg;
    });

  if (!account) {
    return null;
  }

  if (!skipMail && account && (!account.name || !account.email)) {
    return (
      <FillAccount
        user={user}
        account={account}
        onSkip={() => {
          setSkipMail(true);
        }}
      />
    );
  }

  const name = curRoom.name || '';
  const authorizedUsers = curRoom.authorizedUsers || {};
  const usersCount = Object.keys(authorizedUsers).length;

  if (directRecording || audioRecording || audioFinishing || uploading) {
    return (
      <RecordingScreen
        recording={audioRecording}
        finishing={audioFinishing}
        uploading={uploading}
        setUploading={setUploading}
        recordingStart={audioRecordingStart}
        startRecord={audioStartRecord}
        stopRecord={audioStopRecord}
        setDirectRecording={setDirectRecording}
        recCallback={async (recData) => {
          if (recData && recData.id) {
            setUploading(true);
            let recordRoomId;
            let roomRef;
            const recordDate = new Date(recData.startTs);
            const rootRef = firebase.database().ref();
            const userAsPatientRooms = [];
            Object.keys(rooms || {}).forEach((key) => {
              const r = rooms[key];
              const roomAuthorizedUsers = r.authorizedUsers || {};
              if (r.id && roomAuthorizedUsers[user.phoneNumber] === 'patient') {
                userAsPatientRooms.push(r.id);
              }
            });
            if (
              userAsPatientRooms.length === 0 ||
              userAsPatientRooms.length > 1
            ) {
              recordRoomId = uuidv4();
              roomRef = rootRef.child('rooms').child(recordRoomId);
              await roomRef.update({
                id: recordRoomId,
                createdByUserId: user.phoneNumber,
                system: 'WEB',
                color: 4293621107,
                name: account.name || 'Room',
                date: recordDate.toJSON().replace('T', ' '),
              });
              await roomRef
                .child('authorizedUsers')
                .child(user.phoneNumber)
                .set('patient');
            } else {
              recordRoomId = userAsPatientRooms[0];
              roomRef = rootRef.child('rooms').child(recordRoomId);
            }
            console.log(`userAsPatientRooms`, user.phoneNumber, {
              userAsPatientRooms,
            });
            const storageRef = firebase.storage().ref();
            const msgAudioRef = storageRef.child(
              `sounds/${recordRoomId}/${recData.id}.WAV`,
            );

            const msgRef = roomRef.child('messages').child(recData.id);
            const appointmentID = uuidv4();
            const appointmentsRef = roomRef.child('appointments');

            const uploadTask = msgAudioRef.put(recData.blob);
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                  default:
                    console.log('snapshot.state = ', snapshot.state);
                }
              },
              (error) => {
                // Handle unsuccessful uploads
                setUploading(false);
              },
              async () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  console.log('File available at', downloadURL);
                });
                await appointmentsRef.child(appointmentID).update({
                  appointmentTimestamp: recData.startTs,
                  createdByUserId: user.phoneNumber,
                  id: appointmentID,
                  audioId: recData.id,
                  title: `Untitled Appointment`,
                });
                //<<
                const audioLength = recData.endTs - recData.startTs;
                await msgRef.update({
                  id: recData.id,
                  user: user.phoneNumber,
                  appointmentId: appointmentID,
                  time: recordDate.toJSON(),
                  type: 'Audio',
                  ext: 'WAV',
                  soundConfig: {
                    preset: 'WEB_WAV',
                    system: 'WEB',
                  },
                  fileStatus: 'OK',
                  mp3Status: 'NEED',
                  state: 2,
                  duration: audioLength,
                });
                msgRef.on('value', (snap) => {
                  const snapData = snap.val();
                  if (
                    snapData &&
                    snapData.mp3Status &&
                    snapData.mp3Status === 'OK'
                  ) {
                    setUploading(false);
                  }
                });
                setCurRoom(recordRoomId);
              },
            );
          }
        }}
      />
    );
  }

  const { isDesktopSize, staticMenuWidth, popupMenuWidth } = winSize;

  const left = isDesktopSize ? staticMenuWidth : 0;

  const onSetRoom = (id) => {
    setCurRoom(id);
    setLeftMenu(false);
    setRightMenu(false);
  };

  const toggleLeftMenu = () => {
    setLeftMenu(!leftMenu);
    setRightMenu(false);
  };

  const toggleRightMenu = () => {
    setLeftMenu(false);
    setRightMenu(!rightMenu);
  };

  const gotoBottom = () => {
    const scrollingElement = document.getElementById('msgScroller');
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };

  /*
  const gotoBottom = id => {
    //const scrollingElement = document.getElementById(id);
    const scrollingElement = document.getElementById(
      'bottomOfMessagesContainer'
    );
    if (scrollingElement) {
      scrollingElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  */

  const leftMenuStyle = {
    left: popupMenuWidth * (leftMenu ? 0 : -1),
    width: popupMenuWidth,
    opacity: leftMenu ? 1 : 0,
  };

  const rightMenuStyle = {
    right: popupMenuWidth * (rightMenu ? 0 : -1),
    width: popupMenuWidth,
    opacity: rightMenu ? 1 : 0,
  };

  /*
  if (transcriptionMsg) {
    return (
      <TranscriptionScreen
        roomId={curRoomID}
        msgId={transcriptionMsg}
        back={() => {
          setTranscriptionMsg('');
        }}
      />
    );
  }
  */
  return (
    <>
      <ExportModalContext.Provider value={appExportModal}>
        <DownloadModalContext.Provider value={appDownloadModal}>
          <WinSizeContext.Provider value={winSize}>
            <>
              {isDesktopSize ? (
                <div
                  style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: staticMenuWidth,
                    display: 'flex',
                  }}>
                  <div id="leftMenuFixed">
                    <div className="menuHeader">Patients</div>
                    <RoomsList
                      rooms={rooms}
                      onChange={onSetRoom}
                      curRoomID={curRoomID}
                    />
                    <MenuBottomBtn
                      pic={profile}
                      text="Profile Settings"
                      onClick={openProfile}
                    />
                    <MenuBottomBtn
                      pic={help}
                      text="Help & About"
                      onClick={openHelp}
                    />
                  </div>
                </div>
              ) : null}
              {transcriptionMsg && curMsgInRoom ? (
                <TranscriptionScreen
                  left={left}
                  roomId={curRoomID}
                  msgId={transcriptionMsg}
                  msg={curMsgInRoom}
                  appointment={appointment}
                  isMy={user.phoneNumber === curMsgInRoom.user}
                  back={() => {
                    setTranscriptionMsg('');
                  }}
                />
              ) : (
                <div className="Container" style={{ left: left }}>
                  <div id="leftMenu" style={leftMenuStyle}>
                    <span id="leftMenuexit" onClick={toggleLeftMenu}>
                      <img src={close} alt="" />
                    </span>
                    <div className="menuHeader">Patients</div>
                    <RoomsList
                      rooms={rooms}
                      onChange={onSetRoom}
                      curRoomID={curRoomID}
                    />
                    <MenuBottomBtn
                      pic={profile}
                      text="Profile Settings"
                      onClick={() => {
                        openProfile();
                        setLeftMenu(false);
                      }}
                    />
                    <MenuBottomBtn
                      pic={help}
                      text="Help & About"
                      onClick={() => {
                        openHelp();
                        setLeftMenu(false);
                      }}
                    />
                  </div>
                  <div
                    className="menuFill"
                    style={{ display: leftMenu ? 'block' : 'none' }}
                    onClick={toggleLeftMenu}
                  />
                  <div id="rightMenu" style={rightMenuStyle}>
                    <span id="rightMenuexit" onClick={toggleRightMenu}>
                      <img src={arrow_back} alt="" />
                    </span>
                    <div className="roomSettingsHeaderContainer">
                      <div className="roomSettingsHeader">Settings</div>
                      <div className="roomSettingsName">{curRoom.name}</div>
                    </div>
                    <RoomSettings room={curRoom} />
                  </div>
                  <div
                    className="menuFill"
                    style={{ display: rightMenu ? 'block' : 'none' }}
                    onClick={toggleRightMenu}
                  />
                  <div className="Header" style={{ left: left }}>
                    {isDesktopSize ? (
                      <div id="menuButton" />
                    ) : (
                      <div id="menuButton" onClick={toggleLeftMenu}>
                        <img alt="" src={menu} height="24" width="24" />
                      </div>
                    )}
                    <div className="roomHeader">
                      <div className="roomName">{name}</div>
                      <div className="roomMembers">
                        {usersCount + ' members'}
                      </div>
                    </div>

                    <div id="menuButton" onClick={toggleRightMenu}>
                      <img alt="" src={dts} height="24" width="24" />
                    </div>
                  </div>
                  <div id="msgScroller" className="Scroll">
                    <div className="MessagesContainer">
                      <MsgSettingsModalContext.Provider
                        value={appMsgSettingsModal}>
                        <Messages
                          directMsg={directMsg}
                          addMember={() => {
                            createCode(curRoomID);
                          }}
                          name={account?.name}
                          room={rooms[curRoomID]}
                          gotoBottom={gotoBottom}
                          onTranscriptionOpen={(msgId) => {
                            setTranscriptionMsg(msgId);
                          }}
                        />
                      </MsgSettingsModalContext.Provider>

                      <div
                        id="bottomOfMessagesContainer"
                        style={{ height: 5 }}></div>
                    </div>
                  </div>
                  <div className="Footer" style={{ left: left }}>
                    <Composer
                      room={curRoom}
                      user={props.user}
                      onRec={() => {
                        audioStartRecord();
                      }}
                    />
                  </div>
                </div>
              )}
              {profileMenu ? (
                <ProfileMenu
                  user={user}
                  account={account}
                  left={left}
                  back={() => {
                    setProfileMenu(false);
                  }}
                />
              ) : null}
              {helpMenu ? (
                <HelpMenu
                  left={left}
                  back={() => {
                    setHelpMenu(false);
                  }}
                />
              ) : null}
            </>
          </WinSizeContext.Provider>
        </DownloadModalContext.Provider>
      </ExportModalContext.Provider>
      <DownloadModal
        isOpen={appDownloadModal.isOpen}
        toggle={appDownloadModal.toggle}
      />
      <AppExportModal
        user={user}
        isOpen={exporting || appExportModal.isOpen}
        toggle={(val) => {
          setExporting(val);
          appExportModal.toggle(val);
        }}
      />
      <MsgSettingsModal
        isOpen={appMsgSettingsModal.isOpen}
        toggle={appMsgSettingsModal.toggle}
        msg={appMsgSettingsModal.msg}
        onSelect={appMsgSettingsModal.onSelect}
      />
    </>
  );
};

export default withRouter(PreviewPage);

export { Preview };
