import React from 'react';
import styles from './PlayerRange.module.css';

const PlayerRange = ({ minValue, maxValue, step, value, onChange }) => {
  const _onChange = (event) => {
    const newValue = event.target.value || 0;
    console.log(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  const gradValue = Math.round((value / maxValue) * 100);
  const style = {
    borderRadius: '6px',
    background:
      'linear-gradient(90deg,#7674E2 ' +
      gradValue +
      '%,#eeeeee ' +
      (gradValue + 1) +
      '%)',
  };

  return (
    <input
      className={styles.slider}
      type="range"
      min={minValue}
      max={maxValue}
      step={step}
      value={value}
      onChange={_onChange}
      style={style}></input>
  );
};

export default PlayerRange;
