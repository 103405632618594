import React from 'react';
import './RoomListItem.css';

import { formatMenuDate } from './../utils/dateUtils';

export const getRoomLastDate = (room) => {
  const creationDate = new Date(room.date);
  let lastDate = creationDate;
  let roomDateDescription = 'Created';
  const messages = room.messages;
  if (messages) {
    const msgIds = Object.keys(messages);
    const renderMsgs = msgIds
      .map((id) => {
        return messages[id];
      })
      .filter((msg) => msg.timestamp > 0)
      .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

    if (renderMsgs.length > 0) {
      const lastMessage = renderMsgs[renderMsgs.length - 1];
      const modifDate = new Date(lastMessage.timestamp);
      lastDate = modifDate;
      roomDateDescription = 'Edited';
    }
  }

  return [lastDate, roomDateDescription];
};

const getRoomDateDescription = (room) => {
  const [lastDate] = getRoomLastDate(room);
  return formatMenuDate(lastDate);
};

const RoomListItem = ({ room, onClick, isCurRoom }) => {
  const color = room.color || 0;
  const hexColor = '#' + color.toString(16).slice(2);

  const letter = ((room.name || '') + ' ').substring(0, 1).trim();
  const dateDescription = getRoomDateDescription(room);
  return (
    <div
      className={'roomListItem ' + (isCurRoom ? 'currentListItem' : '')}
      onClick={() => onClick(room.id)}>
      <div className="itemCircle" style={{ backgroundColor: hexColor }}>
        <div className="itemLetter">{letter}</div>
      </div>
      <div className="itemDescription">
        <div className="itemName">{room.name || ''}</div>
        <div className="itemTime">{dateDescription}</div>
      </div>
    </div>
  );
};

export default RoomListItem;
