import React, { useState, useEffect } from 'react';
import logo from 'img/logo.svg';
import uploadingImg from 'img/uploading.gif';
import mic from 'img/mic_24px.svg';
import Box from 'components/Box';

const recColor = '#F6706F';

const padNum = (n, zeros = 2) => (n || '0').toString().padStart(zeros, '0');

const formatMs = (ms) => {
  let rest = ms;
  const retArr = [];
  const msInSec = 1000;
  const msInM = msInSec * 60;

  const msInH = msInM * 60;
  let h;
  if (rest % msInH < rest) {
    h = Math.floor(rest / msInH);
    rest = rest % msInH;
  }
  retArr.push(padNum(h));

  let m, s;
  if (rest % msInM < rest) {
    m = Math.floor(rest / msInM);
    rest = rest % msInM;
  }
  if (rest % msInSec < rest) {
    s = Math.floor(rest / msInSec);
    rest = rest % msInSec;
  }
  retArr.push(padNum(m));
  retArr.push(padNum(s));

  return retArr.join(':');
};

const formatTimeFromTs = (startTs) => {
  if (!startTs) return '0:00';
  const value = Date.now() - startTs;
  return formatMs(value);
};

const getColorOpacity = (color, op) => {
  return color.toString() + Math.floor(256 * op).toString(16);
};

const RecBtn = ({ recording, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="calc(50vh - 138px)"
      width={276}
      height={276}
      backgroundColor={
        recording ? getColorOpacity(recColor, 0.11) : 'transparent'
      }
      borderRadius={'100%'}>
      <Box
        display="flex"
        css={{ cursor: 'pointer' }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={194}
        height={194}
        backgroundColor={
          recording ? getColorOpacity(recColor, 0.3) : 'transparent'
        }
        borderRadius={'100%'}>
        <Box
          onClick={onClick}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={120}
          height={120}
          backgroundColor={recColor}
          borderRadius={'100%'}
          zIndex="1">
          {recording ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={30}
              height={30}
              backgroundColor={'#FFFFFF'}
            />
          ) : (
            <img src={mic} alt="" />
          )}
        </Box>
        {recording ? null : (
          <Box
            display={'flex'}
            position="absolute"
            fontFamily="Proxima Nova"
            fontStyle="normal"
            fontWeight="bold"
            fontSize="24px"
            color="#FFFFFF"
            paddingTop="170px"
            zIndex="0">
            Tap To Record
          </Box>
        )}
      </Box>
    </Box>
  );
};

const UploadingAndTranscribing = () => {
  return (
    <Box
      display="flex"
      height="calc(100vh)"
      flexDirection="column"
      backgroundColor={'#F0F0F6'}
      alignItems="center"
      justifyContent={'center'}>
      <Box
        display={'flex'}
        fontFamily="Proxima Nova"
        fontStyle="normal"
        fontWeight="800"
        fontSize="24px"
        color="#2F374D">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="calc(50% - 80px)"
          top="calc(50% - (160px + 29px + 20px))">
          <img src={uploadingImg} width="160px" alt="" />
        </Box>
        {'Uploading & Transcribing...'}
      </Box>
    </Box>
  );
};

const RecordingScreen = ({
  recording,
  finishing,
  uploading,
  setUploading,
  recordingStart,
  startRecord,
  stopRecord,
  setDirectRecording,
  recCallback,
}) => {
  const [rec, setRec] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      if (recordingStart) {
        setRec(formatTimeFromTs(recordingStart));
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [recordingStart]);

  if (uploading || finishing) {
    return <UploadingAndTranscribing />;
  }

  return (
    <Box
      display="flex"
      height="calc(100vh)"
      flexDirection="column"
      backgroundColor={'transparent'}
      alignItems="center">
      <Box marginY={'33px'}>
        {recording ? (
          <Box>
            <Box
              fontStyle="normal"
              fontWeight="600"
              fontSize="17px"
              color="#FFFFFF">
              Recording
            </Box>
            <Box
              fontFamily="Proxima Nova"
              fontStyle="normal"
              fontWeight="300"
              fontSize="67px"
              color="#FFFFFF">
              {rec}
            </Box>
          </Box>
        ) : (
          <>
            <img width="322px" src={logo} alt="" />
          </>
        )}
      </Box>
      <RecBtn
        recording={recording}
        onClick={() => {
          if (recording) {
            setUploading(true);
            setDirectRecording(false);
            stopRecord(recCallback);
          } else {
            startRecord();
          }
        }}
      />
    </Box>
  );
};
export default RecordingScreen;
