import React, { useState } from 'react';
import styled from 'styled-components';
import { firebase } from '../firebase';
import logowithtxt from './logowithtxt.svg';
import arrowBack from './arrow_back.svg';
import useWindowsSize from './../hooks/WindowSize';

const FillContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f6;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: flex-start;
  align-items: center;
`;

const LogoImg = styled.img`
  margin-top: 30px;
`;

const LogoSpace = styled.div`
  height: 40px;
`;

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 295px;
  margin-left: 40px;
  margin-right: 40px;
`;

const HiText = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #333333;

  align-self: flex-start;
`;

const PageHeader = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 19px;
  font-size: 33px;
  line-height: 40px;

  color: #333333;
  font-weight: 800;
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const TextInput = styled.input`
  font-family: 'Proxima Nova';
  background-color: transparent;
  border: none;
  font-weight: 800;
  color: #7674e2;
  font-size: 33px;
  line-height: 45px;

  &::placeholder {
    color: #c0c0cc;
    font-size: 33px;
    line-height: 45px;
    font-weight: 800;
  }
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  height: 61px;
  background-color: #7674e2;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

const ErrorText = styled.p`
  align-self: flex-start;
  text-align: left;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #ee5656;
`;

const RightButton = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  /* identical to box height, or 106% */

  text-align: center;

  color: #7674e2;
  cursor: pointer;
`;

const LeftImgButton = styled.img`
  cursor: pointer;
`;

const MenuRow = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex: 1;
`;

const RenderNameInput = ({ name, error, onNameChange, onNameSubmit }) => {
  const { width } = useWindowsSize();
  const isInvalid = !name;
  return (
    <FillContainer>
      <LogoImg
        src={logowithtxt}
        alt=""
        width="169px"
        height="40px"
        style={{
          alignSelf: 'flex-start',
          marginLeft: '40px',
          display: width > 768 ? 'flex' : 'none',
        }}
      />
      <PageInner>
        <LogoSpace
          style={{
            display: width > 768 ? 'none' : 'flex',
          }}
        />
        <HiText>Hi, there!</HiText>
        <PageHeader>What’s your name?</PageHeader>
        <InputForm onSubmit={onNameSubmit}>
          <TextInput
            id="firstName"
            value={name}
            onChange={(event) => onNameChange(event.target.value)}
            type="text"
            placeholder="First Name"
          />

          <SendButton key="send" disabled={isInvalid} type="submit">
            Next
          </SendButton>
          {error && <ErrorText>{error.message}</ErrorText>}
        </InputForm>
      </PageInner>
    </FillContainer>
  );
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const RenderEmailInput = ({
  email,
  error,
  onEmailChange,
  onEmailSubmit,
  onBack,
  onSkip,
}) => {
  const { width } = useWindowsSize();
  const isInvalid = !validateEmail(email);
  return (
    <FillContainer>
      <LogoImg
        src={logowithtxt}
        alt=""
        width="169px"
        height="40px"
        style={{
          alignSelf: 'flex-start',
          marginLeft: '40px',
          display: width > 768 ? 'flex' : 'none',
        }}
      />
      <PageInner>
        <LogoSpace
          style={{
            display: width > 768 ? 'none' : 'flex',
          }}
        />
        <MenuRow>
          <LeftImgButton
            src={arrowBack}
            width="24px"
            height="24px"
            alt=""
            onClick={onBack}
          />
          <RightButton onClick={onSkip}>Skip</RightButton>
        </MenuRow>
        <PageHeader>What’s your email?</PageHeader>
        <InputForm onSubmit={onEmailSubmit}>
          <TextInput
            id="email"
            value={email}
            onChange={(event) => onEmailChange(event.target.value)}
            type="text"
            placeholder="name@gmail.com"
            autoComplete="email"
          />

          <SendButton key="send" disabled={isInvalid} type="submit">
            Next
          </SendButton>
          {error && <ErrorText>{error.message}</ErrorText>}
        </InputForm>
      </PageInner>
    </FillContainer>
  );
};

const FillAccount = ({ user, account, onSkip }) => {
  const phone = user && user.phoneNumber;
  const [step, setStep] = useState(!account.name ? 0 : 1);
  const [name, setName] = useState(account.name || '');
  const [email, setEmail] = useState(account.email || '');
  const [error] = useState(null);
  const accountRef = firebase
    .database()
    .ref('profiles')
    .child(phone)
    .child('account');
  const onBack = () => {
    setStep(0);
  };
  const onNameChange = (n) => {
    setName(n);
  };
  const onEmailChange = (n) => {
    setEmail(n);
  };
  const onNameSubmit = (event) => {
    event && event.preventDefault();
    accountRef.update({ name: name });
    setStep(1);
  };
  const onEmailSubmit = (event) => {
    event && event.preventDefault();
    accountRef.update({ email: email });
  };

  if (!phone) return null;
  if (!account.name || step === 0) {
    return (
      <RenderNameInput
        name={name}
        error={error}
        onNameChange={onNameChange}
        onNameSubmit={onNameSubmit}
      />
    );
  }
  if (!account.email) {
    return (
      <RenderEmailInput
        email={email}
        error={error}
        onEmailChange={onEmailChange}
        onEmailSubmit={onEmailSubmit}
        onBack={onBack}
        onSkip={onSkip}
      />
    );
  }
  return null;
};

export default FillAccount;
