import React, { useState } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import useWindowsSize from './../hooks/WindowSize';
import icon from './../img/modal_icon.svg';
import close from './../img/modal_close.svg';
import appStore from './../img/appStore_icon.svg';
import googlePlay from './../img/googlePlay_icon.svg';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #7674e2;
  padding: 20px;
  border-radius: 12px;
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalTextHeader = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 120%;
  /* or 36px */

  text-align: center;

  color: #ffffff;

  margin-top: 25px;
`;

const ModalText = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  /* or 23px */

  text-align: center;

  color: #ffffff;

  margin-top: 6px;
  margin-bottom: 30px;
  max-width: 600px;
`;

const ModalTextSpan = styled.span`
  font-weight: bold;
`;

const ImgDivider = styled.div`
  width: 24px;
  height: 24px;
`;

const ModalIcon = styled.img`
  width: 49px;
  height: 49px;
  align-self: center;
`;

export const useDownloadModal = () => {
  const [isOpen, toggle] = useState(false);
  return { isOpen, toggle };
};

const DownloadModal = ({ isOpen, toggle }) => {
  const { width } = useWindowsSize();
  const isMobile = width < 768;
  const ButtonsContainer = isMobile ? CenterColumn : CenterRow;
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Column>
        <CloseContainer>
          <img alt="" src={close} onClick={() => toggle()} />
        </CloseContainer>
        <ModalIcon alt="" src={icon} />
        <ModalTextHeader>Download the Medcorder App</ModalTextHeader>
        <ModalText>
          To get the full Medcorder experience, download the app for{' '}
          <ModalTextSpan>FREE</ModalTextSpan> on iOS or Android.
        </ModalText>
        <ButtonsContainer>
          <img
            width={'179px'}
            height={'52px'}
            alt=""
            src={appStore}
            onClick={() => {
              toggle();
              window.open(
                'https://itunes.apple.com/us/app/medcorder/id1392651594',
              );
            }}
          />
          <ImgDivider />
          <img
            width={'179px'}
            height={'52px'}
            alt=""
            src={googlePlay}
            onClick={() => {
              toggle();
              window.open(
                'https://play.google.com/store/apps/details?id=co.medcorder.app',
              );
            }}
          />
          <div style={{ height: isMobile ? '40px' : '0px' }} />
        </ButtonsContainer>
      </Column>
    </Modal>
  );
};

export default DownloadModal;
