import React, { useEffect, useState, useRef } from 'react';
import './MsgAudio.css';
import './Player';
import Box from 'components/Box';
import { formatAppintmentTs } from 'utils/dateUtils';
import playIcon from 'img/play_circle_white.svg';

const padNum = (n) => (n || '0').toString().padStart(2, '0');

const formatLabel = (_value) => {
  const value = Math.floor(_value);
  let rest = value;
  const secInM = 60;
  const secInH = secInM * 60;
  let h, m, s;
  if (rest % secInH < rest) {
    h = Math.floor(rest / secInH);
    rest = rest % secInH;
  }
  if (rest % secInM < rest) {
    m = Math.floor(rest / secInM);
    rest = rest % secInM;
  }
  s = rest;

  return [h ? padNum(h) : '', padNum(m), padNum(s)]
    .filter((el) => el)
    .join(':');
};

const MsgAudio = ({ msg, appointment, roomId, visible }) => {
  // const [link, setLink] = useState('');
  // const [linkError, setLinkError] = useState(false);
  // console.log(appointment);
  const textRef = useRef();
  const bottomLine = false; //msg.text && msg.text.length > 0;
  const [overflow, setOverflow] = useState(false);
  const [more, setMore] = useState(false);
  const maxh = 110;

  /*
  useEffect(() => {
    if (visible && !link) {
      const audioUrl = 'sounds/' + roomId + '/' + msg.id + '.MP3';
      storageRef
        .child(audioUrl)
        .getDownloadURL()
        .then((link) => {
          setLink(link);
        })
        .catch((e) => {
          setLinkError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  */

  useEffect(() => {
    if (textRef.current) {
      const e = textRef.current;
      if (e.offsetHeight < e.scrollHeight) {
        setOverflow(true);
      }
    }
  }, [msg.text]);

  const style = {};
  if (!more) {
    style.maxHeight = maxh;
    style.overflow = 'hidden';
  }

  return (
    <div className="MsgWithRadius pointer">
      <div className="MsgPaddingHor">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          paddingTop="20px">
          <Box
            fontSize="19px"
            fontWeight="bold"
            lineHeight="135%"
            color="#2f374d">
            {appointment?.title}
          </Box>
          <Box fontSize="17px" lineHeight="135%" color="#2f374d">
            {formatAppintmentTs(appointment?.appointmentTimestamp)}
          </Box>
          <Box fontSize="17px" lineHeight="135%" color="#7674E2" display="none">
            Location...
          </Box>
        </Box>
        <Box paddingTop="20px" height="76px">
          <Box
            width="100%"
            height="56px"
            padding="8px"
            backgroundColor="#7674E2"
            borderRadius="60px"
            display="flex"
            alignItems="center">
            <img src={playIcon} alt="" />
            <Box width="8px" />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box
                fontStyle="normal"
                fontWeight="bold"
                fontSize="16px"
                lineHeight="22px"
                color="#FFFFFF">
                Play
              </Box>
              <Box
                fontStyle="normal"
                fontWeight="normal"
                fontSize="14px"
                lineHeight="22px"
                letterSpacing="0.3px"
                color="#FFFFFF"
                opacity="0.7">
                {formatLabel(msg.duration / 1000)} min
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
      {bottomLine ? <div className="bottomLine"></div> : null}
      {msg.text ? (
        <div
          className="msgText MsgPaddingHor MsgPaddingTop"
          style={style}
          ref={textRef}>
          {msg.text}
        </div>
      ) : null}
      {overflow ? (
        <div className="showMore MsgPaddingHor" onClick={() => setMore(!more)}>
          {more ? 'SHOW LESS' : 'SHOW MORE'}
        </div>
      ) : null}
      <div className={'MsgPaddingBottom'}></div>
    </div>
  );
};

export default MsgAudio;
