import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import { useEffect, useState } from 'react';
import MsgText from './MsgText';
import './Messages.css';
import { getTaskUrl, setAnswerUrl } from './../constants/classify';

const Error = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: red;
`;

const Loading = styled.div`
  font-size: 40px;
  color: white;
`;

const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f6;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: flex-start;
  align-items: center;
`;

const Scroll = styled.div`
  background-color: #f0f0f6;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 13px;
  height: 100%;
  overflow: auto;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0px;
  overflow: auto;
  position: fixed;
  z-index: 1;
  background-color: #f0f0f6;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 13px;
  border-radius: 12px;
`;

const buttonPoses = {
  on: {
    opacity: 1,
    transition: {
      opacity: {
        type: 'tween',
        duration: 200,
      },
    },
  },
  off: {
    opacity: 0.5,
    transition: {
      opacity: {
        type: 'tween',
        duration: 200,
      },
    },
  },
};

const Spacer = styled(posed.div(buttonPoses))`
  margin 10px;
  width: 180px;
  height: 60px;
`;

const Button = styled(posed.div(buttonPoses))`
  margin 10px;
  width: 180px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #7674e2;

  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #ffffff;
  cursor: pointer;
`;

const SelectButton = ({ ans, bid, text, onTap }) => {
  const p = ans === bid ? 'on' : 'off';
  return (
    <Button
      onClick={() => {
        onTap(bid);
      }}
      initialPose="off"
      pose={p}>
      {text}
    </Button>
  );
};

const MedicalClassify = ({ workerID, roomID, msgID }) => {
  const [text, setText] = useState('');
  const [wait, setWait] = useState(true);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState(false);

  const selectAnswer = (ans) => {
    setAnswer(ans);
    fetch(setAnswerUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        workerID: workerID,
        roomID: roomID,
        msgID: msgID,
        answer: ans,
      }), // body data type must match "Content-Type" header
    });
  };

  useEffect(() => {
    fetch(getTaskUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        workerID: workerID,
        roomID: roomID,
        msgID: msgID,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((json = {}) => {
        setText(json.text || '');
        setAnswer(json.answer || '');
        setWait(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setText('');
        setAnswer('');
        setWait(false);
      });
  }, [workerID, roomID, msgID]);

  if (error) {
    return (
      <div className="LogoContainer">
        <Error>Error</Error>
      </div>
    );
  }
  if (wait) {
    return (
      <div className="LogoContainer">
        <Loading>Loading...</Loading>
      </div>
    );
  }
  const msg = {
    text: text,
  };
  return (
    <Conatiner>
      <Scroll>
        <MsgText msg={msg} />
        <Row>
          <Spacer />
          <Spacer />
          <Spacer />
        </Row>
        <RowContainer>
          <Row>
            <SelectButton
              ans={answer}
              bid="MEDICAL"
              text="MEDICAL"
              onTap={selectAnswer}
            />
            <SelectButton
              ans={answer}
              bid="NOTMEDICAL"
              text="NOT MEDICAL"
              onTap={selectAnswer}
            />
            <SelectButton
              ans={answer}
              bid="DUNNO"
              text="DUNNO"
              onTap={selectAnswer}
            />
          </Row>
        </RowContainer>
      </Scroll>
    </Conatiner>
  );
};

export default MedicalClassify;
