import 'recorder/shim';
import React, { useState, useEffect } from 'react';
import waitLogo from './../img/Medcorder.svg';
import './App.css';
import { firebase } from '../firebase';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from 'apollo-link-retry';
import * as routes from '../constants/routes';
import SignInPage from './SignIn';
import PreviewPage from './Preview';
import MedicalClassify from './MedicalClassify';
import UserContext from './../contexts/UserContext';

const gqlPoint = process.env.REACT_APP_GQL_POINT;

const httpLink = createHttpLink({
  uri: `${gqlPoint}/api`,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      console.log('ERROR', error, _operation);
      return !!error;
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = '';
  try {
    token = await firebase.auth().currentUser.getIdToken();
  } catch (e) {
    console.log(e);
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const link = ApolloLink.from([retryLink, authLink, httpLink]);

const client = new ApolloClient({
  link: link,
  // link: concatLink,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  // connectToDevTools: true,
});

const useUser = () => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      setUser(authUser);
    });
  }, []);
  return { authUser: user };
};

const App = () => {
  const searchStr = window.location.search;
  const query = new URLSearchParams(searchStr);
  const phone = query.get('phone');
  const roomID = query.get('roomID');
  const msgID = query.get('msgID');
  const inviteCode = query.get('inviteCode');
  const qr = query.get('qr');
  const { authUser } = useUser();
  const [directExport, setDirectExport] = useState(false);
  const [directPhone, setDirectPhone] = useState(phone || '');
  const [directMsg, setDirectMsg] = useState(msgID || '');
  const [directRoom, setDirectRoom] = useState(roomID || '');
  const [directCode, setDirectCode] = useState(inviteCode || '');
  const [directRecord] = useState(!!qr || false);
  const { pathname } = window.location;
  const routesSIGN_IN = `${routes.SIGN_IN}${searchStr}`;
  const routesPreview = `${routes.PREVIEW}${searchStr}`;

  let redirect = null;

  if (authUser === undefined) {
    return (
      <div className="App">
        <div className="LogoContainer">
          <img alt="wait" src={waitLogo} />
        </div>
      </div>
    );
  }
  const splittedPath = pathname.split('/');
  if (
    splittedPath &&
    splittedPath.length === 6 &&
    splittedPath[1] === 'classify' &&
    splittedPath[2] === 'isMedical'
  ) {
    const workerID = splittedPath[3];
    const roomID = splittedPath[4];
    const msgID = splittedPath[5];
    return (
      <div className="App">
        <MedicalClassify workerID={workerID} roomID={roomID} msgID={msgID} />
      </div>
    );
  }
  if (splittedPath && splittedPath.length === 4) {
    const preview = splittedPath[1];
    if (preview === 'preview' || preview === 'signin') {
      const roomID = splittedPath[2];
      const msgID = splittedPath[3];
      const roomQuery = `roomID=${encodeURIComponent(roomID)}`;
      const msgQuery = msgID ? `&msgID=${encodeURIComponent(msgID)}` : '';
      const phoneQuery = phone ? `&phone=${encodeURIComponent(phone)}` : '';
      const codeQuery = inviteCode
        ? `&inviteCode=${encodeURIComponent(inviteCode)}`
        : '';
      if (!directRoom && roomID) setDirectRoom(roomID);
      if (!directMsg && msgID) setDirectMsg(msgID);
      if (!directPhone && phone) setDirectPhone(phone);
      if (!directCode && inviteCode) setDirectCode(inviteCode);
      const routeName = authUser === null ? 'signin' : 'preview';
      redirect = (
        <Redirect
          to={`/${routeName}?${roomQuery}${msgQuery}${phoneQuery}${codeQuery}`}
        />
      );
    }
  } else if (authUser === null) {
    redirect =
      pathname === routes.SIGN_IN ? null : <Redirect to={routesSIGN_IN} />;
  } else {
    if (
      splittedPath &&
      splittedPath.length === 3 &&
      splittedPath[1] === 'preview'
    ) {
      if (!directRoom) {
        setDirectRoom(splittedPath[2]);
      }
    }
    redirect =
      pathname === routes.PREVIEW ? null : <Redirect to={routesPreview} />;
  }

  if (
    splittedPath &&
    splittedPath.length === 2 &&
    splittedPath[1] === 'export'
  ) {
    if (!directExport) {
      console.log('!!!directExport');
      setDirectExport(true);
    }
    if (authUser === null) {
      redirect =
        pathname === routes.SIGN_IN ? null : <Redirect to={routesSIGN_IN} />;
    } else {
      redirect =
        pathname === routes.PREVIEW ? null : <Redirect to={routesPreview} />;
    }
  }

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={authUser}>
        <Router>
          <div className="App">
            {redirect}
            <Route
              path={routes.SIGN_IN}
              component={() => <SignInPage phone={directPhone} />}
            />
            <Route
              path={routes.PREVIEW}
              component={() => (
                <PreviewPage
                  user={authUser}
                  directRoom={directRoom}
                  directMsg={directMsg}
                  directCode={directCode}
                  directRecord={directRecord}
                  directExport={directExport}
                />
              )}
            />
          </div>
        </Router>
      </UserContext.Provider>
    </ApolloProvider>
  );
};

export default App;
