const copyLink = (url) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(url);
  }
};

export const nativeShare = (url) => {
  if (navigator.share) {
    navigator.share({
      title: 'Share Invite Link',
      url: url,
    });
  } else {
    copyLink(url);
  }
};
